import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettings } from '../app-settings-service/app-settings.service';
import { CommonService } from '../common-service/common-service';
import { RestService } from '../rest-service/rest.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
@Injectable()
export class NetworkService {
    constructor(private commonService: CommonService, private restService: RestService, public locstr: LocalStorageService,
        private toaster: Toaster, public translate: TranslateService) {
    }

    getNeServices(payload: any): Observable<any> {
        var path = AppSettings.GET_NE_SERVICES + "?page=" + payload.page + "&size=" + payload.size + "&searchText=" + payload.searchText + "&paginate=" + payload.paginate;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    getServiceRequestList(data: any): Observable<any> {
        var path = AppSettings.GET_SERVICE_REQUEST;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    scheduleService(data: any): Observable<any> {
        var path = AppSettings.SCHEDULE_SERVICE_REQUEST;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    rejectService(data: any): Observable<any> {
        var path = AppSettings.REJECT_SERVICE_REQUEST;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    getServiceRequestDetails(serviceRequestType: any, serviceRequestId: any): Observable<any> {
        let serviceAPI;
        console.log("serviceRequestType",serviceRequestType);
        if (serviceRequestType == 'EDUCATIONAL') {
            serviceAPI = AppSettings.GET_EDUCATIONAL_DETAILS;
        } else if (serviceRequestType == 'EMERGENCY') {
            serviceAPI = AppSettings.GET_EMERGENCY_DETAILS;
        } else if (serviceRequestType == 'LEGAL_REFERRAL') {
            serviceAPI = AppSettings.GET_LEGAL_DETAILS;
        } else if (serviceRequestType == 'TRANSLATION') {
            serviceAPI = AppSettings.GET_TRANSLATION_DETAILS;
        } else if (serviceRequestType == 'TRANSPORTATION') {
            serviceAPI = AppSettings.GET_TRANSPORT_DETAILS;
        } else {
            serviceAPI = AppSettings.GET_DEFAULT_FORM_DETAILS;
        }
        if (serviceAPI && serviceRequestId) {
            var path = serviceAPI + "?serviceRequestId=" + serviceRequestId;
            return this.restService.getCall(path, null, false, null)
                .catch(this.commonService.handleError);
        } else {
            let message = this.translate.instant("NO_DATA_FOUND");
            return Observable.of({
                message: message,
                code: 200,
                data: null
            });
        }
    }

    acceptServiceRequest(serviceRequestId: any): Observable<any> {
        var path = AppSettings.ACCEPT_NE_SERVICE + "?serviceReqId=" + serviceRequestId;
        return this.restService.postCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    createNeServices(data: any): Observable<any> {
        var path = AppSettings.CREATE_NE_SERVICE;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    updateNeServices(data: any): Observable<any> {
        var path = AppSettings.UPDATE_NE_SERVICE;
        return this.restService.putCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    deleteNeServices(serviceId: any) {
        var path = AppSettings.DELETE_NE_SERVICE + "?serviceId=" + serviceId;
        return this.restService.deleteCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

}