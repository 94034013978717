import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Toaster } from 'ngx-toast-notifications';
import { CommonService } from 'src/providers/common-service/common-service';
import { LocalStorageService } from 'src/providers/local-storage-service/local-storage.service';
import { ProductManagementService } from 'src/providers/product-management-service/product-management-service';
@Component({
  selector: 'app-product-zip-model',
  templateUrl: './product-zip-model.component.html',
  styleUrls: ['./product-zip-model.component.scss']
})
export class ProductZipModalComponent implements OnInit {
  @Output() callback;
  @Input() zipDataArray;
 
  zipState: any;
  zipCodeList:any=[];
  isZipEmpty:any;
  formSubmitted:any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.close();
  }
  constructor(
    public translate: TranslateService,
    public activeModal: NgbActiveModal,
    protected commonService: CommonService,
    protected locstr: LocalStorageService,
    protected toaster: Toaster,
    protected productService: ProductManagementService,
  ) {
    console.log("zipDataArray",this.zipDataArray);
   }

  ngOnInit() {
    console.log("this.zipList..", this.zipDataArray);
    this.zipCodeList =this.zipDataArray.slice();
   }
 /**
   * function to add zip code
   * pop up model
   */
   addzip = (form: NgForm) => {
   console.log('this.zipState.length==',this.zipState.length);
   this.formSubmitted=true;
     if(form.valid){
       if(this.zipState.length<6){
        this.formSubmitted=true;
       }else{
        this.formSubmitted=false;
        this.zipCodeList.push(this.zipState);
        this.zipState = ""; 
       }
      
     } 
   }

   /**
   * function to remove zip code
   * pop up model
   */
    removeSelectedZip = (selectedZip)  => {
      var titleText = this.translate.instant("Fuel Delivery");
      var messageText = "Do you want to remove this zip code?";
      var yesText = this.translate.instant("YES");
      var noText = this.translate.instant("NO");
      this.commonService.showConfirm(titleText, messageText, noText, yesText, () => {
        console.log("Zip not remove");       
      }, () => {
        for( var i = 0; i < this.zipCodeList.length; i++){   
          if ( this.zipCodeList[i] === selectedZip) { 
            this.zipCodeList.splice(i, 1);
            return;
          }
        }
        console.log("Zip remove");
      }, () => {
        console.log("3");
      });    
    }

  finalSubmit = () => {
   // this.zipList =this.zipDataList;
   if(this.zipCodeList && this.zipCodeList.length == 0){
        this.isZipEmpty= true;
        this.callback(this.zipCodeList);
        this.activeModal.dismiss();
   }else{
    this.isZipEmpty= false;
    this.callback(this.zipCodeList);
    this.activeModal.dismiss();
   }
    
  }

  /**
   * function to close 
   * pop up model
   */
  close() {
    this.activeModal.dismiss();
  }
}
