import { Injectable } from '@angular/core';
@Injectable()
export class headerService {
  visible: boolean;
  constructor() { this.visible = false; }

  /**
   * Function to hide side menu & header on login
  */
  hide() {
    console.log("header hide");
    this.visible = false;
  }

  /**
   * Function to show side menu & header other components
  */
  show() {
    console.log("header show");
    this.visible = true;
  }

  /**
   * Function to toggle hide & show of side menu & header
  */
  toggle() { this.visible = !this.visible; }

}