import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { Constants } from '../app-settings-service/app-constant.service';
import { AppSettings } from '../app-settings-service/app-settings.service';
import { CommonService } from '../common-service/common-service';
import { RestService } from '../rest-service/rest.service';

@Injectable({
  providedIn: 'root'
})
export class OrderManagementService {

  constructor(
    private commonService: CommonService,
    private restService: RestService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private constant: Constants
  ) { }


  /**
   * function for getting list of order status types
   */
  getOrdersStatus() {
    var path = AppSettings.GET_ORDERS_STATUS;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function for getting list of order by its types
   */
  getOrderListOnType(orderStatusTypeId, pageNum, fromDate?, toDate?) {
    
    if (orderStatusTypeId == "" && !fromDate) {
      var path = AppSettings.GET_ORDERS_LIST_BY_TYPE + "?page=" + pageNum;
    } else if (orderStatusTypeId == "" && fromDate){
      var path = AppSettings.GET_ORDERS_LIST_BY_TYPE + "?page=" + pageNum + "&from_date=" + fromDate + "&to_date=" + toDate;
    } else if (orderStatusTypeId != "" && !fromDate) {
      var path = AppSettings.GET_ORDERS_LIST_BY_TYPE + "?order_status_id=" + orderStatusTypeId + "&page=" + pageNum;
    }else{
      var path = AppSettings.GET_ORDERS_LIST_BY_TYPE + "?order_status_id=" + orderStatusTypeId + "&page=" + pageNum+ "&from_date=" + fromDate + "&to_date=" + toDate
    }
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
  * function for change order status
  */
  changeOrderStatus(data){
    var path = AppSettings.CHANGE_ORDER_STATUS;
    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }

/**
 * function for getting order details
 */
  getOrderDetails(orderNumber) {
    var path = AppSettings.GET_ORDER_DETAILS + "?order_number=" + orderNumber;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to update order items
   */
  updateOrderItems(data){
    var path = AppSettings.UPDATE_ORDER_ITEMS;
    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }

  
  /**
   * function to add order items to  
   * existing order
   */
  onAddProductItem(data) {
    var path = AppSettings.ADD_ITEM_IN_ORDER;
    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to add order items to  
   * existing order
   */
  searchProduct(searchKey) {
    var path = AppSettings.SEARCH_PRODUCT + '?search_key=' + searchKey;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to add order items to  
   * existing order
   */
  deleteOrderItem(OrderItemId) {
    var path = AppSettings.DELETE_ORDER_ITEM + "?id=" + OrderItemId;
    return this.restService.deleteCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
     * Function to  user detail
     * @param data
     */
  getCustomerOrderList(userId, pageNum, fromDate?, toDate?) {
    if (!fromDate) {
    var path = AppSettings.GET_CUSTOSMER_ORDER_URL + "?user_id=" + userId + "&page=" + pageNum;
    } else if (fromDate){
      var path = AppSettings.GET_CUSTOSMER_ORDER_URL + "?user_id=" + userId + "&page=" + pageNum + "&from_date=" + fromDate + "&to_date=" + toDate;
    }
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }
 
  // cancelOrder(data){
  //   var path = AppSettings.CANCEL_ORDER;
  //   return this.restService.putCall(path, data, false, null)
  //     .catch(this.commonService.handleError);
  // }
}
