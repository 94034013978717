import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { OWL_DATE_TIME_FORMATS } from "ng-pick-datetime";
import { OrderManagementService } from 'src/providers/order-management-service/order-management.service';
// export const MY_CUSTOM_FORMATS = {
//   parseInput: "LL LT",
//   fullPickerInput: "LL LT",
//   datePickerInput: "LL",
//   timePickerInput: "LT",
//   monthYearLabel: "MMM YYYY",
//   dateA11yLabel: "LL",
//   monthYearA11yLabel: "MMMM YYYY",
// };
@Component({
  selector: 'app-delivery-date-time',
  templateUrl: './delivery-date-time.component.html',
  styleUrls: ['./delivery-date-time.component.scss'],
  // providers: [{ provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS }],
})
export class DeliveryDateTimeComponent implements OnInit {

  @Input() messageText;
  @Input() yesText;
  @Input() noText;
  @Output() yesCall;
  @Output() noCall;
  @Input() payload;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  scheduleDate:any;
  deliveryScheduleDate:Date;
  deliveryScheduleTime:Date;
  deliveryDate:any;
  deliveryTime:any;
  constructor(public activeModal:NgbActiveModal,
    private orderManagementService:OrderManagementService) { }

  ngOnInit() {
    console.log("payload : ",this.payload)
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  formatTime(date){
    var t = new Date(date),
    hour = "" + t.getHours(),
    minute = ""+ t.getMinutes();

    if(hour.length < 2){
      hour = "0"+ hour;
    }
    if(minute.length<2){
      minute = "0"+minute;
    }

    return[hour,minute].join(":");
  }

  cancel(){
    this.activeModal.dismiss('Cancel click');
    this.noCall();
  }

  ok(ngForm){
    console.log(ngForm);
    
    //this.orderManagementService.changeOrderStatus(this.payload);
    this.activeModal.dismiss('submit click');
  }

  date(e:any){
    console.log(e); 
    this.deliveryDate=this.formatDate(this.deliveryScheduleDate)
    console.log(this.deliveryDate)
  }

  time(e:any){
    console.log(e); 
    this.deliveryTime=this.formatTime(this.deliveryScheduleTime)
    console.log(this.deliveryTime)
  }


}
