/**
 * custom modal controller module
 * custom modal related functionality
 * Created By : #1166
 * Date :07 Dec 2019
 */

/*------------------------ angular component ----------------------------*/
import { Component, OnInit, Input, Output, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";
@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  formSubmitted: boolean;
  inputData: any;
  serviceData: any = {};
  allowToUpload: boolean = false;
  @Input() title;
  @Input() message;
  @Output() okCall;
  @Output() cancelCall;
  @Output() crossBtnCall;
  @Input() pageName;
  @Input() firstButton;
  @Input() secondButton;
  @Input() selectedObj;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit() {
    if (this.pageName == 'service') {
      this.inputData = this.selectedObj && this.selectedObj.serviceName ? this.selectedObj.serviceName : "";
      this.allowToUpload = this.selectedObj && this.selectedObj.allowToUpload ? this.selectedObj.allowToUpload : "";
    } else {
      if (this.selectedObj) {
        this.inputData = this.selectedObj;
      }
    }
  }

  /**
   * function to dismiss pop up when cancel clicked
   */
  cancel() {
    this.activeModal.dismiss('Cancel click');
    this.cancelCall();
  }

  /**
   * function to dismiss pop up ok clicked
   */
  ok(form: NgForm) {
    this.formSubmitted = true;
    console.log("form", form.valid);
    if (form.valid) {
      this.activeModal.close('Ok click');
      if (this.pageName == 'service') {
        this.serviceData = {
          name: this.inputData,
          allowToUpload: this.allowToUpload
        }
        this.okCall(this.serviceData);
      } else {
        this.okCall(this.inputData);
      }
    }
  }

  /**
   * function to dismiss pop up when cross symboll clicked
   */
  crossBtnClick() {
    this.activeModal.dismiss('Cross click');
  }
}
