import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from '../app-settings-service/app-constant.service';
import { CommonService } from '../common-service/common-service';
import { RestService } from '../rest-service/rest.service';
import { AppSettings } from '../app-settings-service/app-settings.service';
@Injectable({
  providedIn: 'root'
})
export class ProductManagementService {

  constructor(
    private commonService: CommonService,
    private restService: RestService,
    public translate: TranslateService,
    private modalService: NgbModal,
    private constant: Constants
  ) { }

  /**
   * function for getting list of order status types
   */
  getProductData(typeName, pageNum) {
    if (typeName == 'product') {
      var path = AppSettings.GET_PRODUCT_LIST + '?page=' + pageNum;
    } else {
      var path = AppSettings.GET_CATEGORIES_OR_SUBCATEGORIES + '?type=' + typeName + "&page=" + pageNum;
    }

    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to create new category 
   */
  createItem(data, id) {
    if (id != null) {
      var path = AppSettings.UPDATE_CATEGORY;
    } else {
      var path = AppSettings.CREATE_NEW_CATEGORY;
    }

    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to create new sub category
   * or to update existing  sub category
   */
  createNewSubcategory(data, id) {
    if (id == null) {
      var path = AppSettings.CREATE_NEW_SUBCATEGORY;
    } else {
      var path = AppSettings.UPDATE_SUBCATEGORY;

    }
    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function for getting list of order status types
   */
  getCategoryList() {
    var path = AppSettings.GET_CATEGORY_LIST;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
  * function for getting list of order status types
  */
  getSubCategoryList() {
    var path = AppSettings.GET_CATEGORIES_OR_SUBCATEGORIES;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to create new category 
   */
  createNewProduct(data) {
    var path = AppSettings.ADD_PRODUCT;
    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }


  /**
   * function to create new category 
   */
  updateProduct(data) {
    var path = AppSettings.UPDATE_PRODUCT;
    return this.restService.postCall(path, data, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to subcategory on category
   */
  getSubCategoryOnCategory(id) {
    var path = AppSettings.GET_SUBCATEGORY_ON_CATEGORY + '?category_id=' + id;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
  * function to get units of entities
  */
  getUnits() {
    var path = AppSettings.GET_UNITS;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
  * function to get units of entities
  */
  getCategoryDetails(id) {
    var path = AppSettings.GET_CATEGORY_DETAILS + "?id=" + id;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }
  /**
  * function to get get single category details
  */
  getSingleCategoryDetails(catId) {
    var path = AppSettings.GET_SINGLE_CATEGORY_DETAILS + "?category_id=" + catId;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }
  /**
  * function to get get single sub category details
  */
  getSingleSubCategoryDetails(subCatId) {
    var path = AppSettings.GET_SINGLE_SUBCATEGORY_DETAILS + "?subcategory_id=" + subCatId;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

/**
   * function to delete the category
   */
  deleteCategory(data) {
    var path = AppSettings.DELETE_CATEGORY + "?category_id=" + data.id + "&is_active=" + data.is_active;
    return this.restService.deleteCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to delete the sub category
   */
  deleteSubCategory(data) {
    var path = AppSettings.DELETE_SUBCATEGORY + "?subcategory_id=" + data.id + "&category_id=" + data.category_id + "&is_active=" + data.is_active;
    return this.restService.deleteCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to delete the product
   */
  deleteProduct(data) {
    var path = AppSettings.DELETE_PRODUCT + "?id=" + data.id + "&is_active=" + data.is_active;
    return this.restService.deleteCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }

  /**
   * function to get product details
   * @param id 
   */
  getProductDetailsForUpdate(id) {
    var path = AppSettings.GET_SINGLE_PRODUCT_DETAILS + "?product_id=" + id;
    return this.restService.getCall(path, null, false, null)
      .catch(this.commonService.handleError);
  }
}
