/**
 * custom modal controller module
 * custom modal related functionality
 * Created By : #1055
 * Date :12 March 2020
 */

/*------------------------ angular component ----------------------------*/
import { Component, OnInit, Input, Output, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";
@Component({
  selector: 'app-common-input-modal',
  templateUrl: './common-input-modal.component.html',
  styleUrls: ['./common-input-modal.component.scss']
})
export class CommonInputModalComponent implements OnInit {
  formSubmitted: boolean;
  @Input() title;
  @Input() labelText;
  @Output() okCall;
  @Output() cancelCall;
  @Input() firstButton;
  @Input() secondButton;
  @Input() inputData;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  data = {
    id: "",
    message: ""
  };
  constructor(public activeModal: NgbActiveModal) { }
  ngOnInit() {
    this.data = {
      id: this.inputData ? this.inputData.id : "",
      message: this.inputData ? this.inputData.message : ""
    }
  }
  cancel() {
    this.activeModal.dismiss();
    this.cancelCall();
  }
  submit(form: NgForm) {
    this.formSubmitted = true;
    console.log("form", form.valid);
    if (form.valid) {
      this.okCall(this.data);
      this.activeModal.dismiss();
    }
  }
}
