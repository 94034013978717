import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-prescription-model',
  templateUrl: './prescription-model.component.html',
  styleUrls: ['./prescription-model.component.scss']
})
export class PrescriptionModelComponent implements OnInit {
  @Output() callback;
  @Input() dataUrl;
  
  checkedPayload: any[] = [];
  subcatData: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.close();
  }
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {
    console.log("dataUrl", this.dataUrl);
  }
  /**
   * function to close 
   * pop up model
   */
  close() {
    this.activeModal.dismiss();
  }
}
