import { Component, OnInit, Input, Output, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { CommonService } from 'src/providers/common-service/common-service';
import { UserCreationServices } from 'src/providers/user-creation-service/user-creation.service';
import { LocalStorageService } from 'src/providers/local-storage-service/local-storage.service';
import { Toaster } from 'ngx-toast-notifications';
import { Constants } from 'src/providers/app-settings-service/app-constant.service';
@Component({
  selector: 'app-user-filter-modal',
  templateUrl: './user-filter-modal.component.html',
  styleUrls: ['./user-filter-modal.component.scss']
})
export class UserFilterModalComponent implements OnInit {
  @Input() filterData;
  @Input() isInactiveUser;
  @Output() callback;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.close();
  }
  sericeRequestData = {
    status: ""
  }
  filterList: any[] = [];
  loginUserRole;
  public loggedInUserRole: any;
  formSubmitted = false;
  
  constructor(
    public activeModal: NgbActiveModal, 
    protected commonService: CommonService,
    protected userCreationServices: UserCreationServices,
    protected locstr: LocalStorageService, 
    protected toaster: Toaster,
    private constant: Constants
    ) { }

  ngOnInit() {
    var loginData = this.locstr.getObj('userData');
    this.loggedInUserRole = loginData[0].user_role;
    if (this.locstr.get('userRole', false) && this.locstr.get('userRoleId', false)) {
      let userRole = this.locstr.get('userRole', false);
      let userRoleId = this.locstr.get('userRoleId', false);
      this.selectFilterList(userRole);
    }else{
      this.selectFilterList(this.loggedInUserRole);
    }
    
  }


  /**
  * function to populate filter options depending upon role selected in 
  * dashboard dropdown
  */
  public selectFilterList(userRole){
    if (userRole == this.constant.ADMIN){
      this.filterList = ["Name", "Zone", "Region", "Area", "City"];
    } else if ( userRole == this.constant.ZM){
      this.filterList = ["Name", "Region", "Area", "City"];
    } else if ( userRole == this.constant.RM) {
      this.filterList = ["Name", "Area", "City"];
    } else if ( userRole == this.constant.AM) {
      this.filterList = ["Name", "City"];
    } else if ( userRole == this.constant.SM) {
      this.filterList = ["Name"];
    } else if (this.loggedInUserRole == this.constant.ADMIN) {
      this.filterList = ["Name", "Zone", "Region", "Area", "City"];
    } else if (this.loggedInUserRole == this.constant.ZM) {
      this.filterList = ["Name", "Region", "Area", "City"];
    } else if (this.loggedInUserRole == this.constant.RM) {
      this.filterList = ["Name", "Area", "City"];
    } else if (this.loggedInUserRole == this.constant.AM) {
      this.filterList = ["Name", "City"];
    } else if (this.loggedInUserRole == this.constant.SM) {
      this.filterList = ["Name"];
    }
    
    
  }
  /**
 * close the pop up model when cancel button licked
 */
  cancel() {
    if (!this.isInactiveUser) {
      this.sericeRequestData.status = "";
    }
  }
/**
 * close the pop up model when cross xlicked
 */
  close() {
    this.activeModal.dismiss();
  }

  
/**
 * function to submit the data through call back function
 * @param form 
 */
  submit(form: NgForm) {
    console.log("form", form);
    this.formSubmitted = true;
    this.callback(this.sericeRequestData);
    this.activeModal.dismiss();
  }

}
