import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/*---------------------- Guard ----------------------*/
import { CanDeactivateGuard } from '../providers/guard-service/can-deactivate-guard.service';
import { AuthGuard } from '../providers/guard-service/auth-guard.service';

const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('../pages/auth-module/login/login.module').then(m => m.LoginModule)
    },
    {
        pathMatch: 'full',
        path: 'forgot-password',
        loadChildren: () => import('../pages/auth-module/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule)
    },
    {
        pathMatch: 'full',
        path: 'reset-password',
        loadChildren: () => import('../pages/auth-module/reset-password/reset-password.module').then(m => m.ResetPasswordModule)
    },
    {
        pathMatch: 'full',
        path: 'otp-verification',
        loadChildren: () => import('../pages/auth-module/otp-verification/otp-verification.module').then(m => m.OtpVerificationModule)
    },
    {
        path: 'manage-users',
        loadChildren: () => import('../pages/dashboard/dashboard.module').then(m => m.DashboardModule)
    },
    {
        path: 'user-creation',
        loadChildren: () => import('../pages/user-creation/user-creation.module').then(m => m.UserCreationModule)
    },
    
    {
        path: 'change-password',
        loadChildren: () => import('../pages/change-password/change-password.module').then(m => m.ChangePasswordModule)
    },
    {
        pathMatch: 'full',
        path: 'user-details',
        loadChildren: () => import('../pages/dashboard/user-details/user-details.module').then(m => m.UserDetailsModule)
    },
    {
        path: 'profile',
        loadChildren: () => import('../pages/profile/profile.module').then(m => m.ProfileModule)
    },
    {
        path: 'seller-profile',
        loadChildren: () => import('../pages/seller-profile/seller-profile.module').then(m => m.SellerProfileModule)
    },
    {
        path: 'terms',
        loadChildren: ()=> import('../pages/terms-services/terms-services.module').then(m => m.TermsServicesModule)
    },
    {
        path : 'privacy-policy',
        loadChildren: ()=> import('../pages/privacy-policy/privacy-policy.module').then(m => m.PrivacyPolicyModule)
    },
    {
        path: 'seller-edit-profile',
        loadChildren: () => import('../pages/seller-profile/seller-edit-profile/seller-edit-profile.module').then(m => m.SellerEditProfileModule)
    },
    {
        path: 'edit-profile',
        loadChildren: () => import('../pages/profile/edit-profile/edit-profile.module').then(m => m.EditProfileModule)
    },

    {
        
        path: 'order-management',
        loadChildren: () => import('../pages/order-management/order-management.module').then(m => m.OrderManagementModule)
    },
    {
        //pathMatch: '',
        path: 'order-details',
        loadChildren: () => import('../pages/order-management/order-details/order-details.module').then(m => m.OrderDetailsModule)
    },
    {
        path: 'update-orders',
        loadChildren: () => import('../pages/order-management/update-orders/update-orders.module').then(m => m.UpdateOrdersModule)
    },
    {
        pathMatch: 'full',
        path: 'product-management',
        loadChildren: () => import('../pages/product-management/product-management.module').then(m => m.ProductManagementModule)
    },
    {
        pathMatch: 'full',
        path: 'add-product',
        loadChildren: () => import('../pages/product-management/add-product/add-product.module').then(m => m.AddProductModule)
    },
    {
        pathMatch: 'full',
        path: 'create-category-subcategory',
        loadChildren: () => import('../pages/product-management/create-category-subcategory/create-category-subcategory.module').then(m => m.CreateCategorySubcategoryModule)
    },
    {
        pathMatch: 'full',
        path: 'category',
        loadChildren: () => import('../pages/dashboard/category/category.module').then(m => m.CategoryModule)
    },
    {
        pathMatch: 'full',
        path: 'category-details',
        loadChildren: () => import('../pages/product-management/category-details/category-details.module').then(m => m.CategoryDetailsModule)
    },
    {
        pathMatch: 'full',
        path: 'inventory-management',
        loadChildren: () => import('../pages/inventory-management/inventory-management.module').then(m => m.InventoryManagementModule)
    },
   

];

@NgModule({
    imports: [RouterModule.forRoot(routes, { useHash: true })],
    exports: [RouterModule],
    providers: [CanDeactivateGuard, AuthGuard]
})
export class AppRoutingModule { }