import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpModule } from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

/*-------------------------- npm providers -------------------------------*/
import { AppRoutingModule } from './app-routing.module';
import { NgxSpinnerModule } from "ngx-spinner";
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { AngularFireModule } from 'angularfire2';
import { AngularFireStorageModule } from 'angularfire2/storage';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { NgxMaskModule } from 'ngx-mask'
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxTrimDirectiveModule } from 'ngx-trim-directive';
import { ImageCropperModule } from 'ngx-image-cropper';

/*providers*/
import { AppSettings } from '../providers/app-settings-service/app-settings.service';
import { PaginationService } from '../providers/pagination-service/pagination.service';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
/*------------------------ Components ----------------------------*/
import { AppComponent } from './app.component';
import { CommonModalComponent } from '../pages/common-modal/common-modal.component';
import { LanguageComponent } from '../pages/language/language.component';
import { CommonInputModalComponent } from '../pages/common-input-modal/common-input-modal.component';
import { UserFilterModalComponent } from '../pages/user-filter-modal/user-filter-modal.component';
import { ImageCropModalComponent } from '../pages/image-crop-modal/image-crop-modal.component';
import { HeaderComponent } from '../pages/header/header.component';
import { SidebarComponent } from '../pages/sidebar/sidebar.component';
import { LocalStorageService } from '../providers/local-storage-service/local-storage.service';
import { TokenInterceptor } from '../providers/auth-service/TokenInterceptor';
import { Broadcaster } from '../providers/broadcast-service/broadcast.service';
import { AuthServices } from '../providers/auth-service/auth.service';
import { CommonService } from '../providers/common-service/common-service';
import { Constants } from '../providers/app-settings-service/app-constant.service';
import { RestService } from '../providers/rest-service/rest.service';
import { UserService } from '../providers/user-service/user.service';
import { headerService } from '../providers/header-service';
import { SidebarService } from '../providers/sidebar.service';
import { LoaderService } from '../providers/common-service/loader-service';
import { NetworkService } from '../providers/network-service/network.service';
import { UserCreationServices } from 'src/providers/user-creation-service/user-creation.service';
import { EmployeeFieldModalComponent } from '../pages/employee-field-modal/employee-field-modal.component';
import { ProductCatSubcatModalComponent } from '../pages/product-cat-subcat-modal/product-cat-subcat-modal.component';

import { OrderManagementService } from 'src/providers/order-management-service/order-management.service';
import { RadioButtonModule } from 'primeng/radiobutton';
import { PrescriptionModelComponent } from '../pages/order-management/prescription-model/prescription-model.component';
import { UpdateInvModelComponent } from 'src/pages/inventory-management/update-inv-model/update-inv-model.component';
import { ProductZipModalComponent } from 'src/pages/product-zip-model/product-zip-model.component';
import { CancelOrderComponent } from 'src/pages/order-management/pop-ups/cancel-order/cancel-order.component';
import { DeliveryDateTimeComponent } from 'src/pages/order-management/pop-ups/delivery-date-time/delivery-date-time.component';
import { DescriptionModalComponent } from 'src/pages/description-modal/description-modal.component';



export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    SidebarComponent,
    CommonModalComponent,
    LanguageComponent,
    ImageCropModalComponent,
    UserFilterModalComponent,
    CommonInputModalComponent,
    EmployeeFieldModalComponent,
    ProductCatSubcatModalComponent,
    PrescriptionModelComponent,
    UpdateInvModelComponent,
    ProductZipModalComponent,
    CancelOrderComponent,
    DeliveryDateTimeComponent,
    DescriptionModalComponent,
  ],
  entryComponents: [
    CommonModalComponent,
    LanguageComponent,
    ImageCropModalComponent,
    EmployeeFieldModalComponent,
    UserFilterModalComponent,
    CommonInputModalComponent,
    ProductCatSubcatModalComponent,
    PrescriptionModelComponent,
    UpdateInvModelComponent,
    ProductZipModalComponent,
    CancelOrderComponent,
    DeliveryDateTimeComponent
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    HttpModule,
    NgbModule,
    NgxSpinnerModule,
    HttpClientModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    RadioButtonModule,
    InfiniteScrollModule,
    NgxTrimDirectiveModule,
    ImageCropperModule,
    AngularFireModule.initializeApp({
     
      messagingSenderId: "405438932620",
      appId: "1:405438932620:web:4017a43fb111ada845bda9"
    }),
    AngularFireStorageModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxMaskModule.forRoot({
      validation: true,
    }),
    TranslateModule,
    TranslateModule.forChild(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    ToastNotificationsModule.forRoot({ duration: 4000, type: 'primary', position: 'top-center', preventDuplicates: true })
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    LocalStorageService,
    Broadcaster,
    AuthServices,
    CommonService,
    Constants,
    RestService,
    UserService,
    headerService,
    SidebarService,
    LoaderService,
    UserCreationServices,
    NetworkService,
    AppSettings,
    PaginationService,
    OrderManagementService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
