import { Component, OnInit, Input, Output, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from "@angular/forms";
import { CommonService } from '../../providers/common-service/common-service';
import { NetworkService } from 'src/providers/network-service/network.service';
import { Toaster } from 'ngx-toast-notifications';
import { TranslateService } from '@ngx-translate/core';
import { LocalStorageService } from '../../providers/local-storage-service/local-storage.service'
@Component({
  selector: 'app-language',
  templateUrl: './language.component.html',
  styleUrls: ['./language.component.scss']
})
export class LanguageComponent implements OnInit {
  formSubmitted: boolean = false;
  @Input() filterData;
  @Output() callback;
  languageList = { "en": "English", "fr": "French", "es": "Spanish" };
  //languageList = { "en": "English" };
  selectedLanguage: any = "";
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  constructor(public translate: TranslateService, public activeModal: NgbActiveModal, protected commonService: CommonService, private networkService: NetworkService,
    protected toaster: Toaster,  public locstr: LocalStorageService) {
  }

  ngOnInit() {
    this.selectedLanguage = this.translate.getDefaultLang();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  /* 
  *  Change language
  *
  */

  submit(form: NgForm) {
    this.formSubmitted = true;
    if (form.valid) {
      this.formSubmitted = false;
      if (this.selectedLanguage) {
        this.locstr.set('app-language', this.selectedLanguage);
        this.translate.setDefaultLang(this.selectedLanguage);
        this.translate.use(this.selectedLanguage);
      }
      this.cancel()
      this.toaster.open({ text: this.translate.instant("LANGUAGE_CHANGED"), type: 'success' });
    }
  }
}
