import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpErrorResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import 'rxjs/add/operator/do';
import { filter, take, switchMap, catchError, finalize } from 'rxjs/operators';
import { AuthServices } from './auth.service';
import { CommonService } from '../common-service/common-service';
import { Broadcaster } from '../broadcast-service/broadcast.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { Router } from '@angular/router';
import { Toaster } from 'ngx-toast-notifications';
@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    isRefreshingToken: any;
    tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
    errorMsg;
    constructor(private authServices: AuthServices,
        private commonService: CommonService,
        private broadcaster: Broadcaster,
        private localStorageService: LocalStorageService,
        private router: Router, private toaster: Toaster) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf('oauth/token') > -1 || request.url.indexOf('user/resetPassword') > -1
            || request.url.indexOf('otp/requestOtp') > -1 || request.url.indexOf('otp/verify') > -1
            || request.url.indexOf('maps.googleapis.com') > -1) {
            return next.handle(request);
        } else {
            let accessToken = this.getAuthToken();
            if (accessToken) {
                return next.handle(this.addToken(request, accessToken))
                    .catch(error => {
                        if (error instanceof HttpErrorResponse) {
                            this.errorMsg = "";
                            if (error.status === 401) {
                                this.errorMsg = error.message;
                                return this.handle401Error(request, next);
                            } else if (error.status === 406) {
                                this.commonService.hideLoading();
                                this.errorMsg = error.error.message;
                                this.logoutCode();
                                return Observable.throw("");
                            } else {
                                return Observable.throw(error);
                            }
                        } else {
                            return Observable.throw("");
                        }
                    });
            } else {
                console.log("HttpNetworkInterceptor  :: intercept :: else");
                return next.handle(request);
            }
        }
    }

    handle401Error(req: HttpRequest<any>, next: HttpHandler) {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.tokenSubject.next(null);
            return this.authServices.refreshToken(localStorage.getItem('refreshToken')).pipe(
                switchMap((newAuthData: any) => {
                    let accessToken = 'Bearer ' + newAuthData.access_token;
                    if (newAuthData) {
                        this.tokenSubject.next(accessToken);
                        this.localStorageService.set('accessToken', accessToken)
                        return next.handle(this.addToken(req, accessToken));
                    } else {
                        this.logoutCode()
                        return Observable.throw("");
                    }
                }),
                catchError(error => {
                    if (error.url.indexOf('oauth/token') != -1) {
                        this.logoutCode()
                    }
                    return Observable.throw(error);
                }),
                finalize(() => {
                    this.isRefreshingToken = false;
                }));
        } else {
            return this.tokenSubject.pipe(
                filter(token => token != null),
                take(1),
                switchMap(token => {
                    return next.handle(this.addToken(req, token));
                })
            );
        }
    }

    logoutCode() {
        if (this.errorMsg) {
            this.toaster.open({ text: this.errorMsg, type: 'danger' });
        }
        this.broadcaster.broadcast('logout', false);
        this.localStorageService.clearAllLocalStorage();
        // to clear data stored in provider
        this.router.navigate(['/']);
    }

    addToken(request: any, accessToken: string): HttpRequest<any> {
        return request.clone({ setHeaders: { Authorization: accessToken } });
    }

    getAuthToken = () => {
        if (localStorage.getItem('accessToken')) {
            return localStorage.getItem('accessToken');
        }
    }

}