import { Component, OnInit, Input, Output, HostListener } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-crop-modal',
  templateUrl: './image-crop-modal.component.html',
  styleUrls: ['./image-crop-modal.component.scss']
})
export class ImageCropModalComponent implements OnInit {
  formSubmitted: boolean;
  inputData: any = "";
  @Input() title;
  @Input() message;
  @Input() file;
  @Output() callBack;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit() {
  }

  /**
   * close pop up with cancel callback
   */
  cancel() {
    this.activeModal.dismiss('Cancel click');
    this.callBack();
  }

  /**
   * function called when click on ok.
   */
  ok() {
    this.callBack(this.croppedImage);
    this.activeModal.dismiss('Cancel click');
  }


  imageChangedEvent: any = '';
  croppedImage: any = '';

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  /**
   * function get called whenever image cropped
   * @param event  
   */
  imageCropped(event: ImageCroppedEvent) {
     this.croppedImage = event.base64;
    //this.croppedImage = event.file;
  }
}
