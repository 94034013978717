/*--------------------Angular related components---------------*/
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
/*-----------------App Providers---------------------*/
import { AppSettings } from '../app-settings-service/app-settings.service';
import { RestService } from '../rest-service/rest.service';
import { CommonService } from '../common-service/common-service';
import { TranslateService } from '@ngx-translate/core';
import { Constants } from 'src/providers/app-settings-service/app-constant.service';
@Injectable()
export class UserCreationServices {
    errorMsg: string;
    successMsg: string;
    constructor(
        private commonService: CommonService,
        private restService: RestService,
        public translate: TranslateService,
        private modalService: NgbModal,
        private constant: Constants) {
    }

    /**
  * Function to user list 
  * @param data
  */
    getUserList(roleType: any, payload: any) {
        var path = AppSettings.USER_LIST_URL
            + "?roleType=" + roleType
            + "&page=" + payload.page
            + "&size=" + payload.size
            + "&searchText=" + payload.searchText
            + "&contractStatus=" + payload.status;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }
    /**
  * Function to user list 
  * @param data
  */
 getCustomerList(roleType:any) {
    var path = AppSettings.CUSTOMER_LIST_URL  + "?roleType=" + roleType;
    return this.restService.getCall(path, null, false, null)
        .catch(this.commonService.handleError);
}
  

    /**
    * Function to inactive user list 
    * @param data
    */
    getInactiveUserList(roleType: any, payload: any) {
        var path = AppSettings.INACTIVE_USER_LIST_URL
            + "?roleType=" + roleType
            + "&page=" + payload.page
            + "&size=" + payload.size
            + "&searchText=" + payload.searchText;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get pending approval user list 
    * @param data
    */
    getPendingApprovals(payload: any) {
        var path = AppSettings.PENDING_APPROVAL_USER_LIST_URL
            + "?page=" + payload.page
            + "&size=" + payload.size
            + "&searchText=" + payload.searchText;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to edit user 
     * @param data
     */
    getEditUser(data) {
        var path = AppSettings.EDIT_USER_URL;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }
    /**
  * Function to user list 
  * @param data
  */
 editUser(data) {
    var path = AppSettings.EDIT_USER
    return this.restService.postCall(path, data, false, null)
        .catch(this.commonService.handleError);
}
     /**
     * Function to add user 
     * @param data
     */
    addUserOrEmployee(data) {
        var path = AppSettings.ADD_USER_URL;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to add user 
     * @param data
     */
    updateUserOrEmployee(data) {
        var path = AppSettings.UPDATE_USER_EMPLOYEE;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }




   


    /**
     * Function to delete user 
     * @param data
     */
    getDeleteUser(userId) {
        var path = AppSettings.DELETE_USER_URL + "?id=" + userId;
        return this.restService.deleteCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to delete user 
     * @param data
     */
    changeUserEmployee(data) {
        var path = AppSettings.CHANGE_USER_STATUS;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

     /**
     * Function to approve user 
     * @param data
     */
    ChangeUserStatus(status,data) {
        var path ;
        if(status=='approve'){
            path= AppSettings.APPROVE_USER_STATUS+ "?id=" + data.id;;
        }else{
            path= AppSettings.REJECT_USER_STATUS;
        }
        
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }
    
    /**
     * Function to reject user 
     * @param data
     */
     RejectUser(data) {
        var path = AppSettings.REJECT_USER_STATUS;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }
    
    /**
     * Function to delete address 
     * @param data
     */
    getDeleteAddress(id) {
        var path = AppSettings.DELETE_ADDRESS_URL + "?id=" + id;
        return this.restService.deleteCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }
    

    /**
     * Function to delete user/employee profile 
     * @param data
     */
    removeProfilePhoto(id) {
        var path = AppSettings.DELETE_USER_IMAGE_URL + "?id=" + id;
        return this.restService.deleteCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }


    /**
 * Function to delete user 
 * @param data
 */
    activateUser(userId) {
        var path = AppSettings.ACTIVATE_USER + "?userId=" + userId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to delete new contract file 
     * @param data
     */
    deleteNewContract(conractId) {
        var path = AppSettings.DELETE_NEW_CONTRACT_URL + "?conractId=" + conractId;
        return this.restService.deleteCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to  user detail
     * @param data
     */
    getUserDetails(userId) {
        var path = AppSettings.CUSTOSMER_DETAILS_URL + "?userId=" + userId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to  user detail
     * @param data
     */
    getCustomerDetails (id) {
        var path = AppSettings.CUSTOSMER_DETAILS_URL + "?id=" + id;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }
 /**
     * Function to perform get states 
     * @param: data
     */
    public getAllStates ( ): Observable<any> {
        var path = AppSettings.GET_STATES;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
            
       
    }
    /**
     * Function to perform get cities 
     * @param: data
     */
    public getAllCity(stateId): Observable<any> {
        let path = AppSettings.GET_CITY + '?state_id=' + stateId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError); 
    }

    /**
    * Function to perform get states 
    * @param: countryid
    */
    public getAllStatesOnCountry(countryId): Observable<any> {
        let path = AppSettings.GET_STATES_ON_COUNTRY + '?id=' + countryId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }
    /**
     * Function to create user 
     * @param data
     */
    getUserCreation(data: any): Observable<any> {
        console.log("getUserCreation..............", data);
        var path = AppSettings.USER_CREATION_URL;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to get Role list
     * @param data
     */

    getRoleList(data: any): Observable<any> {
        var path = AppSettings.ROLE_LIST_URL;
        console.log("path......", path);

        return this.restService.getCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
  * Function to get Sponsor list
  * @param data
  */

    getSponsorList(roleType): Observable<any> {
        var path = AppSettings.ROLE_TYPE_LIST_URL + "?roleType=" + roleType;;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get Contract Signed Sponsor list
    * @param data
    */

    getSignedSponsorList(roleType): Observable<any> {
        var path = AppSettings.CONTRACT_SIGNED_LIST_URL + "?roleType=" + roleType;;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to create user 
    * @param data
    */
    updateProfile(data: any): Observable<any> {
        var path = AppSettings.UPDATE_USER_DETAILS_URL;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    getUserCheckInDetails(userId, payload: any): Observable<any> {
        var path = AppSettings.USER_CHECKIN_URL
            + "?userId=" + userId
            + "&page=" + payload.page
            + "&size=" + payload.size
            + "&fromDate=" + payload.fromDate
            + "&toDate=" + payload.toDate
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    getUserPaymentInDetails(userId, params): Observable<any> {
        var path = AppSettings.GET_INVOICE_LIST + "?userId=" + userId + "&page=" + params.page + "&size=" + params.size
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to download payment invoice file
     * @param data
     */

    downloadInvoiceFile(invoiceId: any): Observable<any> {
        var path = AppSettings.DOWNLOAD_INVOICE + "?invoiceId=" + invoiceId;
        return this.restService.getFileCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to upload new contract
    * @param data
    */

    uploadNewContract(data, payload): Observable<any> {
        var path = AppSettings.UPLOAD_NEW_CONTRACT_URL + "?programParticipantId=" + payload.programParticipantId + "&sponsorId=" + payload.sponsorId;
        return this.restService.postCall(path, data, false, null, true)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to upload additional files
    * @param data
    */

    uploadAdditionalFiles(payload: any, clientId: string, fileName: string, isMultiPart: boolean): Observable<any> {
        let path = AppSettings.ADDITIONAL_FILE_UPLOAD + "?clientId=" + clientId + "&fileName=" + fileName;
        console.log("uploadAdditionalFiles0", payload);
        return this.restService
            .postCall(path, payload, false, null, isMultiPart)
            .map(res => res)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to delete additional files
    * @param data
    */

    deleteAdditionalFile(uploadedFileId: string): Observable<any> {
        let path = AppSettings.ADDITIONAL_FILE_DELETE + "?uploadedFileId=" + uploadedFileId;
        return this.restService
            .postCall(path, null, false, null)
            .map(res => res)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to open payment modal
    */
    openPaymentModal(component: any, user: any, data: any, paymentList: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.user = user;
        modalRef.componentInstance.payment = data;
        modalRef.componentInstance.paymentList = paymentList;
        modalRef.componentInstance.callback = callback;
    }

    /**
    * Function to get saved cards/bank accounts
    * @param data
    */
    getPaymentTypeList(userId: any) {
        var path = AppSettings.GET_PAYMENT_TYPE_LIST + "?userId=" + userId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to add sbank accounts
    * @param data
    */
    addBankAccount(data: any) {
        var path = AppSettings.ADD_BANK_DETAILS_URL;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get saved cards/bank accounts
    * @param data
    */
    getBankAccount(data: any) {
        var path = AppSettings.GET_BANK_DETAILS_URL + "?customerId=" + data.customerId + "&accountId=" + data.accountId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * Funtion to get all accounts
     */
    getAllAccounts = (userId: any) => {
        let path = AppSettings.GET_ALL_ACCOUNTS + "?userId=" + userId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

   

   

    /**
    * Function to On/Off user checkin video
    * @param data
    */
    changeUserCheckIn(data: any) {
        var path = AppSettings.ON_OFF_USER_CHECKIN;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to import users
    * @param data
    */

    importUsers(data, payload, importType): Observable<any> {
        var importDataUrl;
        if (importType == 'payment') {
            importDataUrl = AppSettings.IMPORT_PAYEMNTS;
        } else {
            importDataUrl = AppSettings.IMPORT_USERS;
        }
        var path = importDataUrl + "?fileType=" + payload.fileType + "&roleType=" + payload.roleType;
        return this.restService.postCall(path, data, false, null, true)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to accpet/reject user profile field changes
    * @param data
    */
    acceptRejectUserProfileChanges(data: any) {
        var path = AppSettings.ACCEPT_REJECT_USER_CHANGES;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get user profile field changes
    * @param data
    */
    getProfileChanges(userId: any) {
        var path = AppSettings.GET_USER_CHANGES_LIST_URL + "?userId=" + userId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

   

    

    /**
    * Function to get user Roles
    */
    getEmpRoles() {
        var path = AppSettings.GET_EMPLOYEE_ROLES ;
        return this.restService.getCall(path, null, false, null)
        .catch(this.commonService.handleError);
    }

    /**
    * Function to get All Employees
    */
    getAllEmployees(id, userRole) {
        if (this.constant.ADMIN == userRole){
            var path = AppSettings.GET_EMPLOYEES;
        }else{
            var path = AppSettings.GET_EMPLOYEES + "?user_role_id=" + id;
        }
        return this.restService.getCall(path, null, false, null)
        .catch(this.commonService.handleError);
    }


    /**
    * Function to get All territories
    */
    getAllTerritories(id) {
        var path = AppSettings.GET_TERRITORIES + "?id=" + id;
        return this.restService.getCall(path, null, false, null)
        .catch(this.commonService.handleError);
    }


    /**
    * Function to get all zones
    */
    getAllZones(countryId) {
        var path = AppSettings.GET_ALL_ZONES + "?country_id=" + countryId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get  zone wise regions
    */
    getZoneWiseRegions(zoneId) {
        var path = AppSettings.GET_ZONEWISE_REGIONS + "?zone_id=" + zoneId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }


    /**
    * Function to get  zone wise regions
    */
    getRegionWiseArea(regionId) {
        var path = AppSettings.GET_REGIONWISE_AREAS + "?region_id=" + regionId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get  zone wise regions
    */
    getAreaWiseCity(areaId) {
        var path = AppSettings.GET_AREAWISE_CITIES + "?area_id=" + areaId;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get  zone wise regions
    */
    addUpdateAddress(data) {
        if(data.id != ''){
            var path = AppSettings.UPDATE_USER_ADDRESS;
        }else{
            var path = AppSettings.ADD_USER_ADDRESS;
        }
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    /**
    * Function to get  country codes
    */
    getCountryCodes() {
        var path = AppSettings.GET_COUNTRY_CODES;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }
    
    
   
}