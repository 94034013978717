import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NgForm } from '@angular/forms';
import { FormsModule } from '@angular/forms';
import { OrderManagementService } from 'src/providers/order-management-service/order-management.service';
import { LocalStorageService } from 'src/providers/local-storage-service/local-storage.service';
import { CommonService } from 'src/providers/common-service/common-service';
import { Toaster } from 'ngx-toast-notifications';
@Component({
  selector: 'app-cancel-order',
  templateUrl: './cancel-order.component.html',
  styleUrls: ['./cancel-order.component.scss']
})
export class CancelOrderComponent implements OnInit {
  inputData: any;
  serviceData: any = {};
  userOrderList:any = [];
  allowToUpload: boolean = false;
  @Input() currentState;
  @Input() buyerName;
  @Input() orderNumber;
  @Input() messageText;
  @Input() titleText;
  @Input() yesText;
  @Input() noText;
  @Input() orderType;
  @Output() callBack;
  @Output() cancelCallback;
  @Input() selectedObj;
  @Input() payload;
  formSubmitted: boolean = false;
  pageName: string;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  cancel() {
    this.activeModal.dismiss('Cancel click');
  }
  constructor(public activeModal:NgbActiveModal, private orderManagementService:OrderManagementService,
    private locstr:LocalStorageService, public commonService:CommonService, protected toaster:Toaster,) { }

  ngOnInit() {
    if (this.pageName == 'service') {
      this.inputData = this.selectedObj && this.selectedObj.serviceName ? this.selectedObj.serviceName : "";
      this.allowToUpload = this.selectedObj && this.selectedObj.allowToUpload ? this.selectedObj.allowToUpload : "";
    } else {
      if (this.selectedObj) {
        this.inputData = this.selectedObj;
      }
    }
    this.userOrderList = this.locstr.get("isUserOrderList", false);
    //console.log("current state :: ", this.currentState);
  }
  
  ok(value) {
    this.formSubmitted = true;
   // this.activeModal.close('Ok click');
    this.payload['cancellationReason'] = value.cancelReason;
    console.log(value);
    console.log(this.payload);
    this.cancel();
    this.orderManagementService.changeOrderStatus(this.payload).subscribe(
       res => 
      //  {
      //   console.log(res);
      //   this.activeModal.close('Ok click');
      //   this.callBack(res)   
      // }

      {
        // this.activeModal.close('Ok click');
        
        this.commonService.showLoading();
        if (res.status == 200) {
          this.commonService.hideLoading();
          var changedOrderStatus = res.data;
          this.callBack(res)
        } else {
          this.toaster.open({ text: res.message, type: "danger" });
          this.callBack(res)
        }
      },
      (error) => {
        this.activeModal.close('Ok click');
        this.commonService.hideLoading();
        if (error) {
          this.toaster.open({ text: error.message, type: "danger" });
          this.callBack(error)
        }
      }
    )


  }
}
