import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from '../providers/local-storage-service/local-storage.service'
import { TranslateService } from '@ngx-translate/core';
import { Broadcaster } from "../providers/broadcast-service/broadcast.service";
import { CommonService } from '../providers/common-service/common-service';
import { Title } from '@angular/platform-browser';

import { version } from '../../package.json';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'FuelDeliverdAdmin';
  public innerHeight: any;
  public innerWidth: any;
  browserLang: any;
  appVersion = version;
  superCategory: any;
  favIcon: HTMLLinkElement = document.querySelector('#favIcon');
  constructor(public locstr: LocalStorageService, public translate: TranslateService,
    private broadcaster: Broadcaster, private router: Router, private commonService: CommonService,
    private titleService: Title) {
      this.getSupercategoryName();
      if (this.superCategory == 'pharma'){
        this.setTitle( 'Curewin Hylico' );
        this.favIcon.href = 'http://biz4grocery.s3-ca-central-1.amazonaws.com/default/05e1e462-23f2-433e-9565-fec7ba601767_icon.ico';
        
      }
      else {
        this.setTitle( 'FuelDelivered App' );
        this.favIcon.href = '../assets/images/favicon.ico';
    
    
      }
    console.log('app component called');
    this.getAppVersion();
    //calculate window height
    this.innerHeight = window.innerHeight;
    this.innerWidth = window.innerWidth;
    this.locstr.setObj('windowHeight', this.innerHeight);
    this.locstr.setObj('windowWidth', this.innerWidth);

    this.initTranslate();
  }

  /**
 * This function will check app version
 * 
 * */
  getAppVersion = () => {
    let version = this.locstr.getObj('appVersion');
    console.log("login.....", version);
    if (version) {
      if (this.appVersion != version) {
        this.broadcaster.broadcast('logout', false);
        this.locstr.clearAllLocalStorage();
        this.locstr.setObj('appVersion', this.appVersion);
        this.router.navigate(['/']);
        console.log("new version found");
      } else {
        console.log("old version found");
      }
    } else {
      this.locstr.setObj('appVersion', this.appVersion);
    }
  }

  /**
  * Function to Set the default language for translation strings, and the current language.
  */
  initTranslate() {
    this.translate.addLangs(['en', 'fr', 'es']);

    let language = this.locstr.get('app-language', 'en');
    console.log("language", language);
    if (language != undefined && language != "undefined") {
      let value = language + "";
      this.translate.setDefaultLang(value);
      this.translate.use(value);
    } else {
      this.translate.setDefaultLang('en');
      this.translate.use('en');
    }
  }

 /**
   * functioin to get superCategory name from common service
   */
  public getSupercategoryName = () => {
    this.superCategory = this.commonService.superCategoryName();
  }

  /**
   * Set the app title
   */
  public setTitle( newTitle: string) {
    this.titleService.setTitle( newTitle );
  }
}
