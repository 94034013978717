/**
* AppSettings provider module
* contains common url
*/

/*--------------------Angular related components---------------*/
import { Injectable } from '@angular/core';
import { Constants } from './app-constant.service';
import { environment } from 'src/environments/environment';

/**
 * AppSettings class : contains common url
*/
@Injectable()
export class AppSettings {
    public static get BASE_URL(): string {
        if (Constants.CURRENT_ENVIRONMENT == 'All') {
       // return 'http://ec2-35-183-45-32.ca-central-1.compute.amazonaws.com:96/'; // dev
       // return 'https://2a39-137-59-64-76.ngrok.io/'  // test
       return "https://admin.fueldelivered.co/"
         } 
         //else if (Constants.CURRENT_ENVIRONMENT == 'Development') { // this code commited because we use same url for test ,dev and testing.
        //     return 'http://ec2-35-183-45-32.ca-central-1.compute.amazonaws.com:90/';
        //     return 'http://d0f8236c6484.ngrok.io/'; // dev
        // } else if (Constants.CURRENT_ENVIRONMENT == 'Production') {
        //     return 'http://ec2-35-183-45-32.ca-central-1.compute.amazonaws.com:90/' 
        //    return 'http://c086d7fcb837.ngrok.io/'; // dev
        // } else {
        //     return 'http://ec2-35-183-45-32.ca-central-1.compute.amazonaws.com:90/';
        //    return 'http://c086d7fcb837.ngrok.io/'; // dev
        // }
    }

    public static get GOOGLE_URL(): string {
        return 'https://maps.googleapis.com/';
    }


    // super category
    public static get SUPER_CATEGORY_URL(): string {
        return environment.super_category;
      }
    /*---------------------- Login Module URL -------------------------------*/
   // public static get LOGIN_URL(): string { return "oauth/token"; }
   public static get LOGIN_URL(): string { return "api/v1/login"; }
    public static get FORGOT_PASSWORD_URL(): string { return "api/v1/forgot-password-seller"; }
    public static get VERIFY_OTP_URL(): string { return "api/v1/verify-forgot-password"; }
    public static get RESET_PASSWORD_URL(): string { return "api/v1/user/forgotPassword"; }
    public static get CHANGE_PASSWORD_URL(): string { return "api/v1/user/changePassword"; }
    public static get RESEND_OTP_URL(): string { return "api/v1/resend-otp-to-user-email"; }
    public static get RESET_PASSWORD(): string { return "api/v1/update-forgot-password"; }
    public static get REFRESH_TOKEN(): string { return "oauth/token"; }
    public static get CHANGE_PASSWORD(): string { return "api/v1/change-password"; }

    /*---------------------User Creation URL ---------------------------------*/
    public static get USER_LIST_URL(): string { return "api/v1/user/list" }
    public static get CUSTOMER_LIST_URL(): string { return "api/v1/get-user-list" }

    public static get INACTIVE_USER_LIST_URL(): string { return "api/v1/user/list/deletedusers" }
    public static get EDIT_USER_URL(): string { return "api/v1/users" }
    public static get ADD_USER_URL(): string { return "api/v1/users" }
    //URL to get user state
    public static get GET_STATES(): string { return 'api/v1/get-all-states'; }
    //URL to get user state
    public static get GET_CITY(): string { return 'api/v1/get-city-on-state'; }
    public static get DELETE_USER_URL(): string { return "api/v1/delete-user" }
    public static get DELETE_USER_IMAGE_URL(): string { return "api/v1/delete-user-profile-pic" }
    public static get DELETE_ADDRESS_URL(): string { return "api/v1/delete-user-address" }
    public static get USER_CREATION_URL(): string { return "api/v1/user/create"; }
    public static get EDIT_USER(): string { return "api/v1/update-user"; }
    public static get SPONSOR_LIST_URL(): string { return "api/v1/sponsor/list"; }
    public static get ROLE_LIST_URL(): string { return "api/v1/role/list"; }
    public static get ROLE_TYPE_LIST_URL(): string { return "api/v1/user/byrole"; }
    public static get CONTRACT_SIGNED_LIST_URL(): string { return "api/v1/user/contractsignedclients"; }
    public static get CUSTOSMER_DETAILS_URL(): string { return "api/v1/get-user-detail"; }
    public static get USER_CHECKIN_URL(): string { return "api/v1/firebase/checkin/list"; }
    public static get USER_PAYMENT_URL(): string { return "api/v1/user/payment"; }
    public static get USER_UPDATE_URL(): string { return "api/v1/user/update"; }
    public static get UPLOAD_USER_IMAGE_URL(): string { return "api/v1/user/uploadprofileimage"; }
    public static get LOCATION_URL(): string { return "api/v1/client/location/get"; }
    public static get DELETE_USER_IMAGE(): string { return "api/v1/user/deleteImage"; }
    public static get UPDATE_USER_IMAGE(): string { return "api/v1/user/updateImage"; }
    public static get GET_USER_DETAILS_URL(): string { return "api/v1/user/profile/details"; }
    public static get UPDATE_USER_DETAILS_URL(): string { return "api/v1/update-user"; }  //api/v1/user/profile/update
    public static get UPLOAD_NEW_CONTRACT_URL(): string { return "api/v1/user/uploadnewcontract"; }
    public static get DELETE_NEW_CONTRACT_URL(): string { return "api/v1/user/deletenewcontract"; }
    public static get ACTIVATE_USER(): string { return "api/v1/user/activate" }
    public static get ADDITIONAL_FILE_UPLOAD(): string { return "api/v1/contract/contractamendment" }
    public static get ADDITIONAL_FILE_DELETE(): string { return "api/v1/contract/delete/contractamendment" }
    public static get DOWNLOAD_CONTRACT_AMENDMENT(): string { return "api/v1/contract/contractamendment/download"; }
    public static get MAKE_PAYMENT(): string { return "api/v1/invoice/payment"; }
    public static get ON_OFF_USER_CHECKIN(): string { return "api/v1/user/checkinpermission"; }
    public static get IMPORT_USERS(): string { return "api/v1/bulkimport/users"; }
    public static get IMPORT_PAYEMNTS(): string { return "api/v1/bulkimport/payments"; }
    public static get PENDING_APPROVAL_USER_LIST_URL(): string { return "api/v1/user/pendingapprovallist" }
    public static get GET_USER_CHANGES_LIST_URL(): string { return "api/v1/user/viewchanges" }
    public static get ACCEPT_REJECT_USER_CHANGES(): string { return "api/v1/user/profilechanges/acceptreject" }
    public static get ADD_GOODWILL_CREDIT(): string { return "api/v1/invoice/goodwillcredit" }
    public static get UNDO_GOODWILL_CREDIT(): string { return "api/v1/invoice/undo/goodwillcredit" }
    

    /*--------------------- Support services ---------------------------------*/
    public static get GET_NE_SERVICES(): string { return "api/v1/admin/noneservice/list"; }
    public static get CREATE_NE_SERVICE(): string { return "api/v1/admin/noneservice/add"; }
    public static get DELETE_NE_SERVICE(): string { return "api/v1/admin/noneservice/delete"; }
    public static get UPDATE_NE_SERVICE(): string { return "api/v1/admin/noneservice/update"; }
    public static get ACCEPT_NE_SERVICE(): string { return "api/v1/client/service/requests/accept"; }
    public static get GET_SERVICE_REQUEST(): string { return "api/v1/client/service/requests/list/all"; }
    public static get SCHEDULE_SERVICE_REQUEST(): string { return "api/v1/client/service/requests/schedule"; }
    public static get REJECT_SERVICE_REQUEST(): string { return "api/v1/client/service/requests/reject"; }
    public static get GET_SERVICE_REQUEST_DETAILS(): string { return "api/v1/client/reqservice/details"; }

    /*--------------------- Support services API ---------------------------------*/
    public static get GET_TRANSLATION_DETAILS(): string { return "api/v1/client/service/translation/details"; }
    public static get GET_EDUCATIONAL_DETAILS(): string { return "api/v1/client/service/educational/details"; }
    public static get GET_EMERGENCY_DETAILS(): string { return "api/v1/client/service/emergency/details"; }
    public static get GET_LEGAL_DETAILS(): string { return "api/v1/client/service/legal/details"; }
    public static get GET_TRANSPORT_DETAILS(): string { return "api/v1/client/service/transport/details"; }
    public static get GET_DEFAULT_FORM_DETAILS(): string { return "api/v1/client/service/default/details"; }

    /*--------------------- contract services ---------------------------------*/
    public static get CONTRACT_TEMPLATE_LIST(): string { return "api/v1/contract/template/list"; }
    public static get CREATE_CONTRACT_TEMPLATE(): string { return "api/v1/contract/add"; }
    public static get GET_CONTRACT_BY_LANGUAGE(): string { return "api/v1/contract/bylanguage"; }
    public static get ASSIGN_CONTARCT(): string { return "api/v1/contract/assign/user"; }
    public static get UPDATE_CONTRACT_TEMPLATE(): string { return "api/v1/contract/update"; }
    public static get DELETE_CONTRACT_TEMPLATE(): string { return "api/v1/contract/template/delete"; }
    public static get CONTRACT_TEMPLATE_ASSIGNED_LIST(): string { return "api/v1/contract/get"; }
    public static get CONTRACT_TEMPLATE_LIST_BY_TYPE(): string { return "api/v1/contract/assigned"; }
    public static get UPLOAD_CONTRACT(): string { return "api/v1/contract/template/upload"; }
    public static get CONTRACT_TEMPLATE_FILE_DOWNLOAD(): string { return "api/v1/contract/template/download/"; }
    public static get ASSIGN_TEMPLATE(): string { return "api/v1/contract/assignto/user"; }
    public static get DOWNLOAD_CONTRACT(): string { return "api/v1/contract/download"; }
    public static get CONTRACT_BEFORE_ASSIGN(): string { return "api/v1/contract/verify"; }

    /*--------------------- google api ---------------------------------*/
    public static get GET_GEOCODE(): string { return "maps/api/geocode/json"; }
    public static get GET_TIMEZONE(): string { return "maps/api/timezone/json"; }

    /*--------------------- payment worksheet ---------------------------------*/
    public static get GET_PAYMENT_WORKSHEET(): string { return "api/v1/user/payment/worksheet/details"; }
    public static get SAVE_PAYMENT_WORKSHEET(): string { return "api/v1/user/payment/worksheet/add"; }
    public static get UPDATE_PAYMENT_WORKSHEET(): string { return "api/v1/user/payment/worksheet/update"; }

    /*--------------------- Contract Invoice  ---------------------------------*/
    public static get GET_INVOICE_LIST(): string { return "api/v1/invoice/byclient"; }
    public static get DOWNLOAD_INVOICE(): string { return "api/v1/invoice/download"; }
    public static get CANCEL_CONTRACT(): string { return "api/v1/contract/cancel"; }

    /*--------------------- Notification Api  ---------------------------------*/
    public static get GET_NOTIFICATION_MSG_LIST(): string { return "api/v1/users/notification/message/list"; }
    public static get ADD_NOTIFICATION_MSG(): string { return "api/v1/users/notification/message/add"; }
    public static get UPDATE_NOTIFICATION_MSG(): string { return "api/v1/users/notification/message/update"; }
    public static get DELETE_NOTIFICATION_MSG(): string { return "api/v1/users/notification/message/delete"; }
    public static get NOTIFICATION_USER_LIST(): string { return "api/v1/user/notificationUsers"; }
    public static get SEND_NOTIFICATION_TO_USERS(): string { return "api/v1/users/notification/client/group"; }
    public static get SAVE_FIREBASE_TOKEN(): string { return "api/v1/firebase/token/save"; }
    public static get USER_LOGOUT(): string { return "api/v1/logout-user"; }
    public static get GET_MONTHLY_PROGRAM_FEE_LIST(): string { return "api/v1/user/monthlyprogramfees"; }

    /*--------------------- Manage Bank Account  ---------------------------------*/
    public static get GET_PAYMENT_TYPE_LIST(): string { return "api/v1/stripe/payment/getAllPaymentOptions" }
    public static get ADD_BANK_DETAILS_URL(): string { return 'api/v1/stripe/payment/addBankAccount' }
    public static get GET_BANK_DETAILS_URL(): string { return 'api/v1/stripe/payment/getLibreBankAccount' }
    public static get GET_CRAD_LIST(): string { return "api/v1/stripe/payment/getAllCreditCards" }
    public static get GET_BANK_LIST(): string { return "api/v1/stripe/payment/getBankAccount" }
    public static get GET_ALL_ACCOUNTS(): string { return 'api/v1/aspgateway/payment/getAllAccounts' }

    /*--------------------- Activity Logs URL ---------------------------------*/
    public static get GET_ACTIVTY_LOG_LIST(): string { return "api/v1/audit/get" }


    /*--------------------- users url ---------------------------------*/
    public static get GET_EMPLOYEE_ROLES(): string { return "api/v1/get-emp-roles" }
    public static get GET_EMPLOYEES(): string { return "api/v1/get-emp-list" }
    public static get GET_TERRITORIES(): string { return "api/v1/get-all-territories" }
    public static get UPDATE_USER_EMPLOYEE(): string { return "api/v1/update-user" }
    public static get ADD_USER_ADDRESS(): string { return "api/v1/add-user-address" }
    public static get UPDATE_USER_ADDRESS(): string { return "api/v1/update-user-address"}
    public static get GET_COUNTRY_CODES(): string { return "api/v1/get-countries" }
    public static get GET_STATES_ON_COUNTRY(): string { return "api/v1/get-states-on-country" }
    public static get CHANGE_USER_STATUS(): string { return "api/v1/change-user-status" }
    public static get APPROVE_USER_STATUS(): string { return "api/v1/users/seller/approve" }
    public static get REJECT_USER_STATUS(): string { return "api/v1/users/seller/reject" }
    public static get GET_CUSTOSMER_ORDER_URL(): string { return "api/v1/view-users-orders-list"; }

/*--------------------- territory related  url ---------------------------------*/
    public static get GET_ALL_ZONES(): string { return "api/v1/get-all-zones" }
    public static get GET_ZONEWISE_REGIONS(): string { return "api/v1/get-regions-on-zone" }
    public static get GET_REGIONWISE_AREAS(): string { return "api/v1/get-areas-on-regions" }
    public static get GET_AREAWISE_CITIES(): string { return "api/v1/get-cities-on-areas" }
   

/*--------------------- order related  url ---------------------------------*/
    public static get GET_ORDERS_STATUS(): string { return "api/v1/get-orders-status" }
   // public static get GET_ORDERS_LIST_BY_TYPE(): string { return "api/v1/view-orders-list" }
    public static get GET_ORDERS_LIST_BY_TYPE(): string { return "api/v1/v2/view-orders-list" }
    public static get CHANGE_ORDER_STATUS(): string { return "api/v1/change-orders-status" }
    public static get GET_ORDER_DETAILS(): string { return "api/v1/view-orders-details" }
    public static get UPDATE_ORDER_ITEMS(): string { return "api/v1/update-order" }
    public static get ADD_ITEM_IN_ORDER(): string { return "api/v1/add-item-to-order" } 
    public static get SEARCH_PRODUCT(): string { return "api/v1/search" }
    public static get DELETE_ORDER_ITEM(): string { return "api/v1/delete-order-item"}
    
/*--------------------- product related  url ---------------------------------*/
    public static get GET_CATEGORIES_OR_SUBCATEGORIES(): string { return "api/v1/categories-subacategory-list" }
    public static get GET_PRODUCT_LIST(): string { return "api/v1/get-all-products-list" }
    public static get CREATE_NEW_CATEGORY(): string { return "api/v1/add-category" }
    public static get CREATE_NEW_SUBCATEGORY(): string { return "api/v1/add-subcategories" }
    public static get GET_CATEGORY_LIST(): string { return "api/v1/categories" }
    public static get ADD_PRODUCT(): string { return "api/v1/add-product" }
    public static get UPDATE_PRODUCT(): string { return "api/v1/update-product" }
    public static get GET_SUBCATEGORY_ON_CATEGORY(): string { return "api/v1/subcategories" }
    public static get GET_UNITS(): string { return "api/v1/get-units" }
    public static get GET_CATEGORY_DETAILS(): string { return "api/v1/categories-details" }
    public static get GET_SINGLE_CATEGORY_DETAILS(): string { return "api/v1/get-category" }
    public static get GET_SINGLE_SUBCATEGORY_DETAILS(): string { return "api/v1/get-subcategory" }
    public static get UPDATE_CATEGORY(): string { return "api/v1/update-category" }
    public static get UPDATE_SUBCATEGORY(): string { return "api/v1/update-subcategories" }
    public static get DELETE_CATEGORY(): string { return "api/v1/delete-category" }
    public static get DELETE_SUBCATEGORY(): string { return "api/v1/delete-subcategories" }
    public static get DELETE_PRODUCT(): string { return "api/v1/delete-product-variants" }
    public static get GET_SINGLE_PRODUCT_DETAILS(): string { return "api/v1/get-product-variants" }


    /*--------------------- inventory related  url ---------------------------------*/
    public static get GET_INVENTORY_LIST(): string { return "api/v1/get-inventory-details" }
    public static get EDIT_INVENTORY_ITEM(): string { return "api/v1/edit-inventory" }
    public static get SEARCH_INV_DATA(): string { return "api/v1/search-inventories" }

    /*--------------------- Stripe related  url ---------------------------------*/
    public static get CREATE_STRIPE_ACCOUNT(): string {return "api/v1/v2/create_stripe_account"}
    public static get GET_STRIPE_STATUS(): string {return "api/v1/v2/get_stripe_account_details"}
}