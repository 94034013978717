import { Component, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-update-inv-model',
  templateUrl: './update-inv-model.component.html',
  styleUrls: ['./update-inv-model.component.scss']
})
export class UpdateInvModelComponent implements OnInit {
  @ViewChild('updateProductForm', { static: false }) public updateProductForm: NgForm;

  formSubmitted: boolean;
  //used to show the inventory 
  // item details when page load
  @Input() invItem;

  //call back variable
  @Output() callback;
  addQuantity = "";
  reduceQuantity = "";
  isShowError = false;
  errAddQnt = false;
  errReduceQnt = false;
  errorMSg = "Either Add Quantity or Reduce Quantity.";
  errorMsgStock = false;
  moreReduceErr ="Reduce item quantity value must be less than or equal to the available quantity in stock.";
  errorMSgNum = "Enter only whole numbers.";
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.cancel();
  }
  constructor(
    public activeModal: NgbActiveModal,
  ) { }
  ngOnInit() {
  }
  close() {
    this.activeModal.dismiss();
  }

  cancel() {
    this.activeModal.dismiss();
  }

  /**
   * function to dissmiss model and pass data to 
   * inv.mgmt.component
   */
  public updateInv(form: NgForm){
    if((this.addQuantity == "" && this.reduceQuantity != "")
    || (this.addQuantity != "" && this.reduceQuantity == "")){
      if(form.status == "VALID"){
        let tempValue = parseInt(this.reduceQuantity);
        if(tempValue != NaN && this.reduceQuantity != "" && tempValue > this.invItem.in_stock_quantity){
          this.isShowError = true;
          this.errorMsgStock = true;
        }else{
          this.callback(this.invItem, this.addQuantity, this.reduceQuantity);
          this.addQuantity = "";
          this.reduceQuantity = "";
          this.activeModal.dismiss();
        } 
      }
    }else{
      this.isShowError = true;
    }
  }

  /**
   * function to to show error
   * when key up event happens
   */
  keyFunc(form: NgForm){
    let tempValue = parseInt(this.reduceQuantity);
    if((this.addQuantity == "" && this.reduceQuantity != "") && form.status == "INVALID"){
      this.isShowError = false;
      this.errReduceQnt = true;
      this.errorMsgStock = false;
    }else if((this.addQuantity != "" && this.reduceQuantity == "") && form.status == "INVALID"){
      this.isShowError = false;
      this.errAddQnt = true;
      this.errorMsgStock = false;
    }else if( (tempValue > this.invItem.in_stock_quantity) &&  this.addQuantity == ""){
      this.isShowError = true;
      this.errorMsgStock = true;
    }else if((this.addQuantity == "" && this.reduceQuantity != "")
      || (this.addQuantity != "" && this.reduceQuantity == "")){
      this.isShowError = false;
      this.errAddQnt = false;
      this.errReduceQnt = false;
      this.errorMsgStock = false;
    }else if(this.addQuantity == "" && this.reduceQuantity == ""){
      this.isShowError = false;
      this.errReduceQnt = false;
      this.errAddQnt = false;
      this.errorMsgStock = false;
    } else{
      this.isShowError = true;
      this.errAddQnt = false;
      this.errReduceQnt = false;
      this.errorMsgStock = false;
    }
  }
}
