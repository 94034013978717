import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { CommonService } from 'src/providers/common-service/common-service';
import { LocalStorageService } from 'src/providers/local-storage-service/local-storage.service';
import { ProductManagementService } from 'src/providers/product-management-service/product-management-service';
@Component({
  selector: 'app-product-cat-subcat-modal',
  templateUrl: './product-cat-subcat-modal.component.html',
  styleUrls: ['./product-cat-subcat-modal.component.scss']
})
export class ProductCatSubcatModalComponent implements OnInit {
  @Output() callback;
  @Input() categoryData;
  @Input() payloadData;
  subCategoryList: any[] = [];
  categoryName: any;
  isSubCatOpen = false;
  checkedPayload: any[] = [];
  subcatData: any;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.close();
  }
  constructor(
    public activeModal: NgbActiveModal,
    protected commonService: CommonService,
    protected locstr: LocalStorageService,
    protected toaster: Toaster,
    protected productService: ProductManagementService,
  ) { }

  ngOnInit() {
    
   }

/**
 * function to get subcategory list
 * clicking on category 
 */
  getSubcategories(category_id, categoryName, catindex, category){
    if(this.categoryName == categoryName){
      this.categoryName = '';
    }else{
      this.categoryName = categoryName;
    }
    if (category.subCategories == undefined){
      this.productService.getSubCategoryOnCategory(category_id)
      .subscribe(
        response => {
          this.commonService.hideLoading();
          if (response.status == 200) {
            if(response.data.subcategories.length == 0){
              this.toaster.open({ text: 'There is no sub-categories in this category. Please add at least one sub-category.', type: 'danger' });
            }else{
            this.categoryData[catindex]['subCategories'] = response.data.subcategories;
            setTimeout(() => {
              this.categoryData[catindex].subCategories.forEach(element => {
                element['isChecked'] = false;
              });
              this.subcatData = this.categoryData[catindex].subCategories;
            }, 1000);
          }
          } else {
            this.toaster.open({ text: response.message, type: 'danger' });
          }
        },
        error => {
          this.commonService.hideLoading();
          if (error) {
            this.toaster.open({ text: error.message, type: 'danger' });
          }
        });
    } 
  }

  /**
   * function to close 
   * pop up model
   */
  close() {
    this.activeModal.dismiss();
  }

  /**
   * on check box selection for category and sub category
   */
  onCheckBoxSelection(event, subcategory){
    subcategory['isChecked'] = !subcategory['isChecked'];
    event.stopPropagation();
  }


  /**
   * call bacj function when for product category and subcategory get selected
   * this.categoryData :- selected details passed to call back
   */
  submit(){
    this.callback(this.categoryData);
    this.activeModal.dismiss();
  }

}
