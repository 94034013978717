import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
/*--------------------- npm Providers---------------------------*/
import { NgbModal, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from "ngx-spinner";
import { Toaster } from 'ngx-toast-notifications';
/*----------------- Providers---------------------*/
import { Constants } from '../app-settings-service/app-constant.service';
import { LocalStorageService } from '../../providers/local-storage-service/local-storage.service';
import { Broadcaster } from "../../providers/broadcast-service/broadcast.service";
/*------------------Pages---------------------*/
import { CommonModalComponent } from '../../pages/common-modal/common-modal.component';
import { AppSettings } from '../app-settings-service/app-settings.service';
import { RestService } from '../rest-service/rest.service';
import { Observable } from 'rxjs';
import * as FileSaver from "file-saver";
import { environment } from 'src/environments/environment';
import { CancelOrderComponent } from 'src/pages/order-management/pop-ups/cancel-order/cancel-order.component';
import { DeliveryDateTimeComponent } from 'src/pages/order-management/pop-ups/delivery-date-time/delivery-date-time.component';
import { DescriptionModalComponent } from 'src/pages/description-modal/description-modal.component';
@Injectable()
export class CommonService {
    loader: any;
    modalRef: any;
    setControllerTitle: any;
    isGrocery: any;
    isPharma: any;
    superCategory: any;
    processingLocationList = [this.translate.instant("LIBRE_OFFICE"), this.translate.instant("REMOTE_LOCATION")];
    countryCodeList = [
        {
            value: '+1',
            label: 'United States & Canada'
        },
        {
            value: '+52',
            label: 'Mexico'
        },
        {
            value: '+53',
            label: 'Cuba'
        },
        {
            value: '+91',
            label: 'India'
        },
        {
            value: '+1-849',
            label: 'Dominican Republic'
        },
        {
            value: '+509',
            label: 'Haiti'
        },
        {
            value: '+1-876',
            label: 'Jamaica'
        },
        {
            value: '+692',
            label: 'Marshall Islands'
        },
        {
            value: '+685',
            label: 'Samoa'
        },
        {
            value: '+1-284',
            label: 'British Virgin Islands'
        },
        {
            value: '+1-787',
            label: 'Puerto Rico'
        },
        {
            value: '+1-345',
            label: 'Cayman Islands'
        },
        {
            value: '+1-340',
            label: 'US Virgin Islands'
        },
        {
            value: '+58',
            label: 'Venezuela'
        }
    ];
    constructor(
        public translate: TranslateService,
        private broadcaster: Broadcaster,
        private constant: Constants,
        private locstr: LocalStorageService,
        private router: Router,
        private modalService: NgbModal,
        private spinner: NgxSpinnerService,
        private restService: RestService,
        private toaster: Toaster) {

    }
    /**
        * Function to open modal on add organization
        * @param cancelText - Cancel button text
        * @param okText - Ok button text
        * @param callBack - Ok button callBack 
        */
    openComponentModal(pageName: any, component: any, data: any, title: any, cancelText: string, actionText: string, customClassName: string, actionButtonCallBack: any, cancelButtonCallBack: any, message?: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: customClassName
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.headerMessage = message;
        modalRef.componentInstance.pageName = pageName;
        modalRef.componentInstance.selectedObj = data;
        modalRef.componentInstance.firstButton = cancelText;
        modalRef.componentInstance.secondButton = actionText;
        modalRef.componentInstance.okCall = actionButtonCallBack;
        modalRef.componentInstance.cancelCall = cancelButtonCallBack;
        modalRef.componentInstance.title = title;
    }

    /**
    * Function to open modal for comment and submit
    * @param cancelText - Cancel button text
    * @param okText - Ok button text
    * @param callBack - Ok button callBack 
    */
    openCommonInputModal(component: any, data: any, title: any, cancelText: string, actionText: string, labelText: string, actionButtonCallBack: any, cancelButtonCallBack: any, customClassName?: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: customClassName
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.inputData = data;
        modalRef.componentInstance.firstButton = cancelText;
        modalRef.componentInstance.secondButton = actionText;
        modalRef.componentInstance.labelText = labelText;
        modalRef.componentInstance.okCall = actionButtonCallBack;
        modalRef.componentInstance.cancelCall = cancelButtonCallBack;
        modalRef.componentInstance.title = title;
    }

    /**
    * Function to open modal filter modal
    * @param callBack - Ok button callBack 
    */
    openFilterModal(component: any, filterData: any, dateType: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.filterData = filterData;
        modalRef.componentInstance.dateType = dateType;
        modalRef.componentInstance.callback = callback;
    }

    /**
    * Function to open service request filter modal
    * @param callBack - Ok button callBack 
    */
    openServiceRequestFilterModal(component: any, filterData: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.filterData = filterData;
        modalRef.componentInstance.callback = callback;
    }

    /**
* Function to open service request filter modal
* @param callBack - Ok button callBack 
*/
    openModal(component: any, data: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.data = data;
        modalRef.componentInstance.callback = callback;
    }

    /**
    * Function to open modal to accept/reject service request
    * @param isAccept - boolean to specify accept/reject
    * @param title - modal title        * 
    */
    openAcceptRejectModal(component: any, title: any, isSchedule: any, serviceRequest: any, callBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.isSchedule = isSchedule;
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.serviceRequest = serviceRequest;
        modalRef.componentInstance.callBack = callBack;
    }

    /**
     * Function to show confirm pop-up
     * @param titleText - Header text
     * @param messageText - Message to show
     * @param cancelText - Cancel button text
     * @param okText - Ok button text
     * @param callBack - Ok button callBack 
     */
    showConfirm(titleText: string, messageText: string, cancelText: string, actionText: string, cancelCallBack: any, callBack: any, crossCallBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(CommonModalComponent, ngbModalOptions);
        modalRef.componentInstance.title = titleText;
        modalRef.componentInstance.message = messageText;
        modalRef.componentInstance.firstButton = cancelText;
        modalRef.componentInstance.secondButton = actionText;
        modalRef.componentInstance.okCall = callBack;
        modalRef.componentInstance.cancelCall = cancelCallBack;
        modalRef.componentInstance.crossBtnCall = crossCallBack;
    }

    showDescription(description,yesCallback) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'date-time-popup'
        };
        const modalRef = this.modalService.open(CommonModalComponent, ngbModalOptions);
        modalRef.componentInstance.title = "Seller Description";
        modalRef.componentInstance.message = description;
        modalRef.componentInstance.firstButton = "OK",
        modalRef.componentInstance.okCall = yesCallback

      
    }

    showDiscardConfirm(yesCallback, discardCallback, crossBtnCallback) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(CommonModalComponent, ngbModalOptions);
        modalRef.componentInstance.title = "Warning";
        modalRef.componentInstance.message = "Do you want to save this as a draft ?";
        // modalRef.componentInstance.firstButton = "Discard";
        // modalRef.componentInstance.secondButton = "Yes";
        modalRef.componentInstance.okCall = yesCallback;
        modalRef.componentInstance.cancelCall = discardCallback;
        modalRef.componentInstance.crossBtnCall = crossBtnCallback;
    }
    /**
     * Function to show Alert pop-up
     * @param titleText - Header text
     * @param messageText - Message to show
     * @param okText - Ok button text
     * @param callBack - Ok button callBack 
     */
    showAlert(titleText: string, messageText: string, okText: string, callBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(CommonModalComponent, ngbModalOptions);
        modalRef.componentInstance.title = titleText;
        modalRef.componentInstance.message = messageText;
        //  modalRef.componentInstance.firstButton = okText;
        modalRef.componentInstance.okCall = callBack;
        modalRef.componentInstance.cancelCall = '';
        modalRef.componentInstance.crossBtnCall = '';
    }

    /**
     * Function to show Alert pop-up
     * @param titleText - Header text
     * @param messageText - Message to show
     * @param okText - Ok button text
     * @param callBack - Ok button callBack 
     */
    showAlertClose(titleText: string, messageText: string, okText: string, callBack: any, crossCallBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(CommonModalComponent, ngbModalOptions);
        modalRef.componentInstance.title = titleText;
        modalRef.componentInstance.message = messageText;
        //modalRef.componentInstance.firstButton = okText;
        modalRef.componentInstance.okCall = callBack;
        modalRef.componentInstance.cancelCall = '';
        modalRef.componentInstance.crossBtnCall = crossCallBack;
    }

    /**
     * Function to show confirm pop-up
     * @param titleText - Header text
     * @param messageText - Message to show
     * @param cancelText - Cancel button text
     * @param okText - Ok button text
     * @param callBack - Ok button callBack 
     */
    


    cancelPopup(payload,currentState,buyerName,orderNumber,orderType,titleText,yesText,noText,callBack, cancelcallback){
        let ngbModalOptions:NgbModalOptions = {
            backdrop : 'static',
            keyboard: false ,
            windowClass: 'cancel-popup',
        }

        const modalRef = this.modalService.open(CancelOrderComponent, ngbModalOptions);
        modalRef.componentInstance.buyerName = buyerName;
        modalRef.componentInstance.orderNumber = orderNumber;
        modalRef.componentInstance.currentState = currentState;
        modalRef.componentInstance.orderType = orderType;
        modalRef.componentInstance.titleText = titleText;
        modalRef.componentInstance.yesText = yesText;
        modalRef.componentInstance.noText = noText;
        modalRef.componentInstance.callBack = callBack;
        modalRef.componentInstance.cancelcallback = cancelcallback;
        modalRef.componentInstance.payload = payload;
        
    }

    openScheduleDateTime(titleText:string, messageText:string, yesText:String, noText: String, yesCall:any, cancelCall:any,payload?:any){
        let ngbModalOptions:NgbModalOptions = {
            backdrop : 'static',
            keyboard: false ,
            windowClass: 'date-time-popup',
        }

        const modalRef = this.modalService.open(DeliveryDateTimeComponent, ngbModalOptions);
        modalRef.componentInstance.titleText = titleText;
        modalRef.componentInstance.messageText = messageText;
        modalRef.componentInstance.yesText = yesText;
        modalRef.componentInstance.noText = noText;
        modalRef.componentInstance.yesCall = yesCall;
        modalRef.componentInstance.cancelCall = cancelCall;
        modalRef.componentInstance.payload = payload;
    }

    showDeliveryPopup(yesCallback, discardCallback, crossBtnCallback) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(CommonModalComponent, ngbModalOptions);
        modalRef.componentInstance.title = "CONFIRMATION";
        modalRef.componentInstance.message = "Are you sure you want to confirm the payment for above delivery price?";
        modalRef.componentInstance.firstButton = "DISMISS";
        modalRef.componentInstance.secondButton = "Confirm";
        modalRef.componentInstance.okCall = yesCallback;
        modalRef.componentInstance.cancelCall = discardCallback;
        modalRef.componentInstance.crossBtnCall = crossBtnCallback;
    }

    uploadFile(payload: any, userId: boolean, isMultiPart: boolean): Observable<any> {
        console.log("common isMultiPart", isMultiPart);
        let path = AppSettings.UPLOAD_USER_IMAGE_URL + "?userId=" + userId;;
        return this.restService
            .postCall(path, payload, false, null, isMultiPart)
            .map(res => res)
            .catch(this.handleError);
    }

    deleteFile(fileId: any): Observable<any> {
        let path = AppSettings.DELETE_USER_IMAGE + "?profileImageId=" + fileId;
        return this.restService
            .deleteCall(path, null, false, null)
            .map(res => res)
            .catch(this.handleError);
    }

    updateImage(data: any): Observable<any> {
        let path = AppSettings.UPDATE_USER_IMAGE;
        console.log("data", data);
        return this.restService
            .putCall(path, data, false, null)
            .map(res => res)
            .catch(this.handleError);
    }

    userSeverLogout(): Observable<any> {
        let path = AppSettings.USER_LOGOUT;
        return this.restService.getCall(path, null, false, null)
            .map(res => res)
            .catch(this.handleError);
    }

    /*
 * Function to download files
 * */
    public downloadFile = (files, type?) => {
        let fileData = this.base64ToArrayBuffer(files.data.data),
            blob = new Blob([fileData], { type: files.data.fileType });
        FileSaver.saveAs(blob, files.data.fileName);
    };

    public base64ToArrayBuffer = base64 => {
        let binaryString = window.atob(base64),
            binaryLen = binaryString.length,
            bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    };

    /**
     * logout the app
     * */
    logout() {
        let titleText: string = this.translate.instant("LOGOUT_TITLE");
        let messageText: any = this.translate.instant("LOGOUT_MESSAGE");
        console.log("LOGOUT_MESSAGE", messageText);
        let cancelText: string = this.translate.instant("NO");
        let okText: string = this.translate.instant("YES");
        this.showConfirm(titleText, messageText, cancelText, okText, () => {
            console.log("cancel logout");
        }, () => {
            this.userLogout();
        }, () => { });
    }

    userLogout = (message?) => {
        this.showLoading();
        this.userSeverLogout().subscribe(res => {
            this.hideLoading();
            if (res.code == 200 || res.status == 200) {
                if (message) {
                    this.toaster.open({ text: message, type: 'success' });
                } else {
                    this.toaster.open({ text: res.message, type: 'success' });
                }
                this.clearAllLoginData();
            } else {
                this.toaster.open({ text: res.message, type: 'danger' });
                this.clearAllLoginData();
            }
        },
            error => {
                this.hideLoading();
                if (error) {
                    this.toaster.open({ text: error.message, type: 'danger' });
                    this.clearAllLoginData();
                }
            })
    }

    /**
     * Function to show coming soon popup
     */
    comingSoonPopup = () => {
        let title = this.translate.instant("INFO");
        let message = this.translate.instant("COMING_SOON");
        let ok = this.translate.instant("OK");
        this.showConfirm(title, message, "", ok, () => { }, () => {
            console.log("got confim ok");
        }, () => { });
    }

    /**
  * Function to hide/show password
  * @param: inputType
  * @param: showPassword
  * @param: callback
  */
    hideShowPassword = (inputType: string, showPassword: boolean, callback: any) => {
        if (inputType == 'password') {
            showPassword = false;
            inputType = 'text';
            callback(showPassword, inputType);
        }
        else {
            showPassword = true;
            inputType = 'password';
            callback(showPassword, inputType);
        }
    };

    /**
    * Function to show loader
    * 
    */
    showLoading = () => {
        this.spinner.show();
    };

    /**
    * Function to show loader
    * 
    */
    hideLoading = () => {
        this.spinner.hide();
    };

    saveAsPdf(fileName, fileContent) {
        this.writeContents(fileContent, fileName + '.pdf', 'application/pdf');
    }

    writeContents(content, fileName, contentType) {
        var a = document.createElement('a');
        var file = new Blob([content], { type: contentType });
        a.href = URL.createObjectURL(file);
        a.download = fileName;
        a.click();
    }

    downoadFile(fileName, url) {
        var a = document.createElement('a');
        a.href = url;
        a.download = fileName;
        a.click();

    }

    saveFile(fileName, fileContent) {
        this.writeContents(fileContent, fileName, '*');
    }

    /**
   * Function to open modal to image cropper service request
   * @param isAccept - boolean to specify accept/reject
   * @param title - modal title        * 
   */
    openImageCroperModal(component: any, title: any, file: any, callBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
           
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.file = file;
        modalRef.componentInstance.callBack = callBack;
    }

    /**
        * Function to open service request filter modal
        * @param callBack - Ok button callBack 
        */
    openServiceContractModal(component: any, filterData: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.filterData = filterData;
        modalRef.componentInstance.callback = callback;
    }
    /**
     * Function to open modal to contract service request
     * @param isAccept - boolean to specify accept/reject
     * @param title - modal title        * 
     */
    openContractModal(component: any, title: any, callBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.title = title;
        modalRef.componentInstance.callBack = callBack;
    }

    /**
     * Function to open modal to show user changed profile fields 
     */
    openUserProfileChangesModal(component: any, userId: any, customClassName: any, callBack: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: customClassName
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.userId = userId;
        modalRef.componentInstance.callBack = callBack;
    }

    /**
     * Function to open modal to show uploaded result of user changed profile  
     */
    importUserInfoModal(component: any, data: any, customClassName: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: customClassName
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.data = data;
    }

    /**
    * Function to open modal to user filter 
    * @param isAccept - boolean to specify accept/reject
    * @param title - modal title        * 
    */
    openUserModal(component: any, filterData: any, isInactiveUser: boolean, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.filterData = filterData;
        modalRef.componentInstance.isInactiveUser = isInactiveUser;
        modalRef.componentInstance.callback = callback;
    }

    /**
    * Function to open modal to employee fields selection  
    */
    openEmployeeFieldModal(component: any, employeeData: any, selectedRole, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.employeeData = employeeData;
        modalRef.componentInstance.selectedRole = selectedRole;
        modalRef.componentInstance.callback = callback;
    }

    /**
    * Function to open modal for 
    * assigning category, sub category
    * to product 
    */
    openProductModal(component: any, categoryData: any, customClassName: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: customClassName
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.categoryData = categoryData;
        modalRef.componentInstance.callback = callback;
    }


    openZipModal(component: any, zipDataArray: any, customClassName: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: customClassName
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.zipDataArray = zipDataArray;
        modalRef.componentInstance.callback = callback;
    }
    /**
    * Function to open modal for 
    * assigning category, sub category
    * to product 
    */
    openPrescriptionModal(component: any, dataUrl: any, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'my-class'
        };
        const modalRef = this.modalService.open(component, ngbModalOptions,);
        modalRef.componentInstance.dataUrl = dataUrl;
        modalRef.componentInstance.callback = callback;
    }

    clearAllLoginData = () => {
        this.broadcaster.broadcast('logout', false);
        let selectedLanguage = this.locstr.get('app-language', 'en');
        this.locstr.clearAllLocalStorage();
        this.router.navigate(['/']);
        if (this.modalService.hasOpenModals()) {
            this.modalService.dismissAll();
        }
        this.locstr.set('app-language', selectedLanguage);
    }

    // translate(key) {
    //     this.translate.get(key).subscribe((translatedText: string) => { return  translatedText; })
    // }
    /**
    * Function to perform common error handling
    * @param error
    */
    handleError = (errorResponse: Response | any) => {
        console.log("errorResponse..", errorResponse);
        if ((errorResponse || errorResponse != null) && errorResponse.name != 'TimeoutError') {
            // let error = errorResponse.json();
            let error = errorResponse.error;
            if (error && (error.code == 401 || error.code == 406)) {
                setTimeout(() => {
                    this.clearAllLoginData();
                }, 1000);
            } else {
                if (error && error.message && (error.code != 0)) {
                    if (error.message == this.constant.ERROR_NETWORK_UNAVAILABLE) {
                        return Observable.throw(error);
                    } else {
                        console.log('handle error', error);
                        var err: any;
                        err = error;
                        if (error && err) {
                            if (error.code == 401 || error.code == 406) {
                                setTimeout(() => {
                                    this.clearAllLoginData();
                                }, 1000);
                            } else {
                                return Observable.throw(err);
                            }
                        } else {
                            this.showAlert("Error", this.constant.ERROR_MSG_UNABLE_TO_CONNECT, "OK", () => { });
                        }
                    }
                } else {
                    var errorMsg: string = this.constant.ERROR_MSG_UNABLE_TO_CONNECT;
                    if (error && !error.message && (error.statusCode == 0)) {
                        errorMsg = this.constant.UNABLE_TO_CONNECT_SERVER_MSG;
                        this.showAlert("Error", errorMsg, "OK", () => { });
                    }
                }
            }
        } else {
            this.showAlert("Error", this.constant.ERROR_MSG_UNABLE_TO_CONNECT, "OK", () => { });
        }
        return Observable.throw(err);
    }

    /**
    * Function to open modal for 
    * updating inventory item
    */
    openUpdateIvn(component: any, invItem, callback: any) {
        let ngbModalOptions: NgbModalOptions = {
            backdrop: 'static',
            keyboard: false,
            windowClass: 'update-invtr',
        };
        const modalRef = this.modalService.open(component, ngbModalOptions);
        modalRef.componentInstance.invItem = invItem;
        modalRef.componentInstance.callback = callback;
    }
    /**
   * function to return superCategoryName
   */
    public superCategoryName = () => {
        // if (environment.super_category == "b77a8b13-7571-4633-b82e-692cc4b3e370") {
            if (environment.super_category == "e2572d3b-c831-4981-9408-661378748a14") {         //Rohit supecategory
            this.superCategory = 'grocery'
        } else {
            this.superCategory = 'pharma'
        }
        return this.superCategory;
    }
}   