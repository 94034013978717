// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  name: 'default',
 
 //api_base: "http://ec2-35-183-45-32.ca-central-1.compute.amazonaws.com:90",
 "api_base": "http://ec2-35-183-45-32.ca-central-1.compute.amazonaws.com:96",

        // super_category id for grocerry
        //"super_category":"b77a8b13-7571-4633-b82e-692cc4b3e370"
          "super_category" : "e2572d3b-c831-4981-9408-661378748a14" //Rohit Supercategory
        // super_category id for pharma
        //"super_category": "975e63aa-bb72-4146-990f-27360f1a1739"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.


