/**
 * common sidebar functionality
 * Created By : #1166
 * Date : 07 Dec, 2019
 */
/*--------------------Angular components---------------*/
import {
  Component,
  OnInit,
  ViewEncapsulation,
  Renderer2,
  NgZone,
} from "@angular/core";
import { Router, NavigationEnd } from "@angular/router";
/*---------------------------- Provider -----------------------------------*/
import { SidebarService } from "../../providers/sidebar.service";
import { LocalStorageService } from "../../providers/local-storage-service/local-storage.service";
import { CommonService } from "../../providers/common-service/common-service";
import { Broadcaster } from "../../providers/broadcast-service/broadcast.service";
import { Constants } from "../../providers/app-settings-service/app-constant.service";
@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  styleUrls: ["./sidebar.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class SidebarComponent implements OnInit {
  isManageUser: boolean = false;

  isNonEmergencyServices: boolean = false;
  isCourts: boolean = false;
  isTermCondition: boolean = false;
  isBeaconNumber: boolean = false;
  isActivityLog: boolean = false;
  isbankAccount: boolean = false;
  superCategory: any;
  isManageContractServices: boolean = false;
  isManageContractTemplateServices: boolean = false;
  previousUrl: string;
  isNotification: boolean = false;
  loginUserRole;
  appVersion = "";
  currentEnvironment = "";
  tabData: any;
  constructor(
    public constants: Constants,
    public sidebar: SidebarService,
    private router: Router,
    private broadcaster: Broadcaster,
    private commonService: CommonService,
    public locstr: LocalStorageService,
    private zone: NgZone,
    private renderer: Renderer2
  ) {
    this.routeChangeDetector();
    var loginData = this.locstr.getObj("userData");
    if (loginData) {
      this.zone.run(() => {
        this.loginUserRole = loginData[0].user_role;
      });
    }
  }

  ngOnInit() {
    this.getSupercategoryName();
    var loginData;
    this.zone.run(() => {
      loginData = this.locstr.getObj("userData");

      if (loginData) {
        this.loginUserRole = loginData[0].user_role;
      }
    });
    // var loginData = this.locstr.getObj('userData');
    // this.loginUserRole = loginData[0].user_role;
    this.tabData = this.locstr.get("selectedTab", false);
    this.broadcaster
      .on<any>("selectedTab")

      .subscribe((message) => {
        this.tabData = this.locstr.get("selectedTab", false);

        if (this.tabData === "isProductMgmt") {
          this.tabData = "isProductMgmt";
        } else {
          this.tabData = "isUserMgmt";
        }
      });
    // Register route change event to handle active current menu
    this.broadcaster.on<any>("logout").subscribe((message) => {
      this.renderer.removeClass(document.body, "menu-open");
    });
    this.broadcaster.on<any>("user-logged-in").subscribe((message) => {
      this.loginUserRole = this.locstr.getObj("userData")
        ? this.locstr.getObj("userData")[0].user_role
        : "";
      console.log("loginUserRole...====.", this.loginUserRole);
    });
    if (!this.loginUserRole) {
      //this.loginUserRole = this.locstr.getObj('userData') ? this.locstr.getObj('userData').data.role[0] : "";
    }
    this.appVersion = this.locstr.getObj("appVersion");
    this.currentEnvironment = Constants.CURRENT_ENVIRONMENT;
  }
  closeSidebar = () => {
    this.broadcaster.broadcast("menu-close", false);
    this.renderer.removeClass(document.body, "menu-open");
  };

  /*
   *   Below local storage variables used to show selected tab when user goto back from details/internal pages
   *   When user click on menu it gets cleaned
   *
   */
  onTabSelection = (userType) => {
    this.locstr.removeObj("serviceRequest");
    this.locstr.removeObj("courtData");
    this.locstr.removeObj("role");
    this.closeSidebar();
    //  this.locstr.get('', false);
    if (userType == "user") {
      this.broadcaster.broadcast("isUserMgmt", true);
      this.locstr.set("selectedTab", "isUserMgmt");
      this.tabData = "isUserMgmt";
      this.locstr.removeObj("viewName");
    } else if (userType == "employee") {
      this.broadcaster.broadcast("isEmployeeMgmt", true);
      this.locstr.set("selectedTab", "isEmployeeMgmt");
      this.tabData = "isEmployeeMgmt";
      this.locstr.removeObj("viewName");
    } else if (userType == "order") {
      this.broadcaster.broadcast("isOrderMgmt", true);
      this.router.navigate(["/order-management"]);
      this.locstr.set("selectedTab", "isOrderMgmt");
      this.tabData = "isOrderMgmt";
      this.locstr.removeObj("viewName");
      //this.commonService.comingSoonPopup();
    } else if (userType == "category") {
      this.broadcaster.broadcast("isCategoryMgmt", true);
      this.router.navigate(["/category"]);
      this.locstr.set("selectedTab", "isCategoryMgmt");
      this.tabData = "isCategoryMgmt";
      this.locstr.removeObj("viewName");
      // this.commonService.comingSoonPopup();
    } else if (userType == "product") {
      this.broadcaster.broadcast("isProductMgmt", true);
      this.router.navigate(["/product-management"]);
      this.locstr.set("selectedTab", "isProductMgmt");
      this.tabData = "isProductMgmt";
    } else if (userType == "inventory") {
      this.broadcaster.broadcast("isInventoryMgmt", true);
      this.router.navigate(["/inventory-management"]);
      this.locstr.set("selectedTab", "isInventoryMgmt");
      this.tabData = "isInventoryMgmt";
      // this.commonService.comingSoonPopup();
    } else if (userType == "complaint") {
      // this.broadcaster.broadcast('isComplaintMgmt', true);
      // this.router.navigate(['/product-management']);
      // this.locstr.set('selectedTab', 'isComplaintMgmt');
      // this.tabData = 'isComplaintMgmt';
      this.commonService.comingSoonPopup();
    }
  };

  /**
   * function to log out and close the sidebar
   */
  logout = () => {
    this.closeSidebar();
    this.commonService.logout();
  };
  /**
   * Function to redirect page
   * @param pageName - page name
   */
  gotoPage = (pageName: any) => {
    console.log("function called", pageName);

    if (pageName == "manage-user") {
      this.router.navigate(["/manage-users"]);
    }
  };
  /**
   * This function will call on route change and header is managed through it
   * */
  routeChangeDetector() {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        console.log("prev:", this.previousUrl);
        if (
          event["url"] === "/manage-users" ||
          (event["url"] === "/contract-detail" &&
            this.previousUrl == "/user-details") ||
          event["url"] === "/user-creation" ||
          event["url"] === "/payment-worksheet" ||
          event["url"] === "/user-details"
        ) {
          this.isManageUser = true;
        } else {
          this.isManageUser = false;
        }

        // if (event['url'] === '/support-services' || event['url'] === '/request-of-service') {
        //     this.isNonEmergencyServices = true;
        // } else {
        //     this.isNonEmergencyServices = false;
        // }
        // if (event['url'] === '/courts' || event['url'] === '/add-court') {
        //     this.isCourts = true;
        // } else {
        //     this.isCourts = false;
        // }
        // if (event['url'] === '/term-condition') {
        //     this.isTermCondition = true;
        // } else {
        //     this.isTermCondition = false;
        // }
        // if (event['url'] === '/beacon-number') {
        //     this.isBeaconNumber = true;
        // } else {
        //     this.isBeaconNumber = false;
        // }
        // if (event['url'] === '/activity-log') {
        //     this.isActivityLog = true;
        // } else {
        //     this.isActivityLog = false;
        // }
        // if (event['url'] === '/manage-bank-account') {
        //     this.isbankAccount = true;
        // } else {
        //     this.isbankAccount = false;
        // }
        // if (event['url'] === '/manage-contracts' || event['url'] === '/manage-contract-detail') {
        //     this.isManageContractServices = true;
        // } else {
        //     this.isManageContractServices = false;
        // }
        // if (event['url'] === '/manage-contracts-template'
        //     || (this.previousUrl === '/manage-contracts-template' && event['url'] == '/contract-detail')) {
        //     this.isManageContractTemplateServices = true;
        // } else {
        //     this.isManageContractTemplateServices = false;
        // }
        // if (event['url'] === '/notification-list' || event['url'] === '/send-notification') {
        //     this.isNotification = true;
        // } else {
        //     this.isNotification = false;
        // }
        this.previousUrl = event.url;
      }
    });
  }

  gotoTermCondition() {
    this.router.navigate(['/term-condition']);
  }

  /**
   * functioin to get superCategory name from common service
   */
  public getSupercategoryName = () => {
    this.superCategory = this.commonService.superCategoryName();
  };
}
