import { Component, OnInit, ViewEncapsulation, ChangeDetectorRef, Renderer2, NgZone } from '@angular/core';
import { Router } from '@angular/router';
/*---------------------------- Provider -----------------------------------*/
import { LocalStorageService } from '../../providers/local-storage-service/local-storage.service';
import { Broadcaster } from "../../providers/broadcast-service/broadcast.service";
import { CommonService } from '../../providers/common-service/common-service';
import { headerService } from '../../providers/header-service';
import { UserService } from '../../providers/user-service/user.service';
import { LanguageComponent } from '../language/language.component';
@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None
})



export class HeaderComponent implements OnInit {
    groceryLogo:any;
    pharmaLogo:any;  
    superCategory: any;
    isMenuOpen: boolean = false;
    fullName: any;
    params: any = {
        searchText: "",
        page: 0,
        size: 25,
        fromDate: "",
        toDate: "",
        courtTypeId: "",
    };
    localUserData: any = [];
    isSeller: boolean = false;
    constructor(
        public locstr: LocalStorageService,
        private userService: UserService,
        private router: Router,
        private broadcaster: Broadcaster,
        private cdr: ChangeDetectorRef,
        private commonService: CommonService,
        public header: headerService,
          private zone: NgZone,
        private renderer: Renderer2) {

    }

    ngOnInit() {
        this.localUserData = this.locstr.getObj('userData');
        console.log("this.localUserData ngOninit...", this.localUserData);

        this.broadcaster.on<any>('user-logged-in')
        .subscribe(message => {
            console.log("message user logged in.mmmmmmmmmmmmm."+message)
            
                this.localUserData = this.locstr.getObj('userData');
          
        console.log("this.localUserData. changesssssssssssssssssss..", this.localUserData);
        if(this.localUserData[0].user_role == 'Seller') {
            this.isSeller = true;
        } else {
            this.isSeller = false;
        }

        this.fullName = this.localUserData[0].first_name + " " + this.localUserData[0].last_name;
        console.log("fullname: ",this.fullName);
        
        });

        this.broadcaster.on<any>('profile-changed')
        .subscribe(message => {
            this.zone.run(() => {
                 this.localUserData=null;
            this.localUserData = this.locstr.getObj('userData');
              });
               this.fullName = this.localUserData[0].first_name + " " + this.localUserData[0].last_name;
        if(this.localUserData[0].user_role == 'Seller') {
            this.isSeller = true;
        } else {
            this.isSeller = false;
        }
        });
        if(this.localUserData[0].user_role == 'Seller') {
                this.isSeller = true;
            } else {
                this.isSeller = false;
            }

            this.fullName = this.localUserData[0].first_name + " " + this.localUserData[0].last_name;
        this.getSupercategoryName();
        this.broadcaster.on<any>('menu-close')
            .subscribe(message => {
                this.isMenuOpen = message;
                console.log("is menu open ==> ",message);
                
            });
        this.broadcaster.on<any>('profile-image-changed')
            .subscribe(message => {
                console.log("profile-image-changed..", message);
                let localUserData = this.locstr.getObj('userData');
               // localUserData.data.imageUrl = message;
               // this.locstr.setObj('userData', localUserData);
               // this.loadUserData();
                //console.log("local user data", this.localUserData(), this.loadUserData);
            });
        // if (this.locstr.getObj('userData')) {
        //     this.localUserData = this.locstr.getObj('userData');
        // }
        this.groceryLogo = "../../assets/images/fd_new_logo_1.png";
        this.pharmaLogo  = "../../assets/images/logo.png"
    }

    /**
     * functon to toggle the menu
     */
    toggleMenu() {
        this.isMenuOpen = !this.isMenuOpen;
        if (this.isMenuOpen) {
            this.renderer.addClass(document.body, 'menu-open');
        } else {
            this.renderer.removeClass(document.body, 'menu-open');
        }
    }
    
 /**
 * functioin to get superCategory name from common service
 */
public getSupercategoryName = () => {
    this.superCategory = this.commonService.superCategoryName();
  }
}
