import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
/*----------------- Providers ---------------------*/
import { RestService } from '../rest-service/rest.service';
export interface paginationInfo {
    url: string,
    data: any,
    size: number,
    offset: number,
    filter: string,
    page: number,
    isEOL: boolean,
    isLoadMore: boolean,
    isLoadFailed: boolean,
    isLoading: boolean,
    searchDriverName: string,
    fromDate: string,
    toDate: string
}
@Injectable()
export class PaginationService {
    defaultPaginationInfo: paginationInfo = {
        url: '',
        data: [],
        size: 15,
        offset: 0,
        filter: 'ALL',
        page: 0,
        isEOL: false,
        isLoadMore: false,
        isLoadFailed: false,
        isLoading: false,
        searchDriverName: '',
        fromDate: '',
        toDate: ''
    }

    constructor(private restService: RestService) {
    }

    /**
     * This function will return copy of defaultPaginationInfo
     * */
    getDefaultPaginationInfo() {
        return Object.assign({}, this.defaultPaginationInfo);
    }

    getPaginationData(paginationObj: paginationInfo): Observable<any> {
        let path = paginationObj.url + '?size=' + paginationObj.size + '&page=' + paginationObj.page;
        return this.restService.getCall(path, null, false, null)
            .catch(error => Observable.throw(error));
    }

}