
/**
 * This service will hold logged in user data and return if needed
 * */
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
/*-------------------- Providers ----------------------------*/
import { AppSettings } from '../app-settings-service/app-settings.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { RestService } from '../rest-service/rest.service';
import { CommonService } from '../common-service/common-service';
@Injectable()
export class UserService {
    userData: any;
    mapRadius: any;
    matchCriteria: any;
    isUserLoggedIn: boolean;
    panelList: any;
    accessoryList: any;
    constructor(private restService: RestService, public locstr: LocalStorageService,
        private commonService: CommonService) {
    }

    /**
     * This function will return copy of logged in user details
     * */
    getUser() {
        let userCopy: any;
        if (this.userData) {
            userCopy = Object.assign({}, this.userData);
        } else {
            userCopy = this.locstr.getObj('loggedInUser');
        }
        return userCopy;
    }

    /**
     * Function to get current user profile details
     * @param data
     */
    getUserProfileDetails(): Observable<any> {
        var path = AppSettings.GET_USER_DETAILS_URL;
        return this.restService.getCall(path, null, false, null)
            .catch(this.commonService.handleError);
    }

    /**
     * This function will store details of logged in user
     * */
    setUser = (user: any) => {
        console.log("defaultColorCodeValues inside login------------->>", user);
        this.locstr.setObj('loggedInUser', user);
        this.userData = user;
    }

    /**
     * This function will hold boolean value whether user logged in
     * */
    setIsUserLoggedIn(isLoggedIn: boolean) {
        this.locstr.setObj('isUserLoggedIn', isLoggedIn);
        this.isUserLoggedIn = isLoggedIn;
    }

    /**
     * This function will return boolean value whether user logged in
     * */
    getIsUserLoggedIn = (): boolean => {
        let isUserLoggedIn = Object.assign({}, this.isUserLoggedIn);
        if (this.isUserLoggedIn) {
            isUserLoggedIn = Object.assign({}, this.isUserLoggedIn);
        } else {
            isUserLoggedIn = this.locstr.getObj('isUserLoggedIn');
        }
        console.log("isUserLoggedIn", isUserLoggedIn);
        return isUserLoggedIn;
    }

    getUserLocation(data, payload: any): Observable<any> {
        var path = AppSettings.LOCATION_URL
            + "?page=" + payload.page
            + "&size=" + payload.size
            + "&searchText=" + payload.searchText;
        return this.restService.postCall(path, data, false, null)
            .catch(this.commonService.handleError);
    }

    createStripeAccount(payload:any){
        var path = AppSettings.CREATE_STRIPE_ACCOUNT;
        return this.restService.postCall(path, payload, false, null )
        .catch(this.commonService.handleError);
    }

    getStripeStatus(){
        var path = AppSettings.GET_STRIPE_STATUS;
        return this.restService.getCall(path, null, false, null)
        .catch(this.commonService.handleError);
    }

}