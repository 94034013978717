import { Injectable } from '@angular/core';

/**
 * Constants class : contains constants and strings
*/
@Injectable()
export class Constants {
    ERROR_NETWORK_UNAVAILABLE: string;
    PLEASE_WAIT_TEXT: string;
    ERROR_MSG_UNABLE_TO_CONNECT: string;
    UNABLE_TO_CONNECT_SERVER_MSG: string;
    ADMIN: string = "Admin";
    ZM: string = "Zonal Manager";
    RM: string = "Regional Manager";
    AM: string = "Area Manager";
    SM: string = "Sales Manager";
    SELLER:string = "Seller";
    static CURRENT_ENVIRONMENT: string = "All";             // Local, Test, Production, Development
    constructor() {
        this.PLEASE_WAIT_TEXT = 'Please wait.';
        this.ERROR_MSG_UNABLE_TO_CONNECT = 'Unable to connect with server. Please try again.';
        this.ERROR_NETWORK_UNAVAILABLE = 'Please check internet connection and try again.';
        this.UNABLE_TO_CONNECT_SERVER_MSG = "Unable to connect to server, please try again.";
        
    }
}    