import { Component, HostListener, Input, OnInit, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { Constants } from 'src/providers/app-settings-service/app-constant.service';
import { CommonService } from 'src/providers/common-service/common-service';
import { LocalStorageService } from 'src/providers/local-storage-service/local-storage.service';
import { UserCreationServices } from 'src/providers/user-creation-service/user-creation.service';
import { FormArray, FormBuilder, FormControl, FormGroup, NgForm } from '@angular/forms';
@Component({
  selector: 'app-employee-field-modal',
  templateUrl: './employee-field-modal.component.html',
  styleUrls: ['./employee-field-modal.component.scss']
})
export class EmployeeFieldModalComponent implements OnInit {
  form: FormGroup;
  @Input() employeeData;
  @Input() selectedRole;
  @Output() callback;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    this.close();
  }
  fieldList = [];
  valueArray = [];
  selectedRoleMsg: string = "Territory" 
  constructor(
    public activeModal: NgbActiveModal,
    protected commonService: CommonService,
    protected userCreationServices: UserCreationServices,
    protected locstr: LocalStorageService,
    protected toaster: Toaster,
    
  ) { 
    
  }


  ngOnInit() {
   this.fieldList = this.employeeData;
    if (this.selectedRole == "Zonal Manager"){
      this.selectedRoleMsg = "Zone";
    } else if (this.selectedRole == "Regional Manager"){
      this.selectedRoleMsg = "Region";
    } else if (this.selectedRole == "Area Manager") {
      this.selectedRoleMsg = "Area";
    } else if (this.selectedRole == "Sales Manager") {
      this.selectedRoleMsg = "City";
   }
  }

  /**
   * function to close pop up 
   */
  close() {
    this.activeModal.dismiss();
  } 

/**
   * function to update array according to 
   * selected values
   */
  fieldsChange(values: any, name,  i) {
    if (name.isChecked == true){
      this.fieldList[i]['isChecked'] = false;
    } else if (values.currentTarget.checked){
      this.valueArray[i] = this.fieldList[i].id;
      this.fieldList[i]['isChecked'] = true;
    }else if (!values.currentTarget.checked) {
      this.valueArray.splice(i, 1);
    }
    
  }

  /**
   * function to submit form with selectes values
   * and close pop up
   * @param form 
   */
  submit(form: NgForm) {
    this.callback(this.valueArray, this.fieldList);
    this.activeModal.dismiss();
  }
}
