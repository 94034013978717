import { Component, HostListener, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-description-modal',
  templateUrl: './description-modal.component.html',
  styleUrls: ['./description-modal.component.scss']
})
export class DescriptionModalComponent implements OnInit {

  description:any;

  @Input() title;
  @HostListener('window:popstate', ['$event'])
  onPopState(event) {
    
  }
  constructor() { }

  ngOnInit() {
    console.log("title: ",this.title);
    
  }

}
