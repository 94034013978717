import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/timeout';
import 'rxjs/add/observable/throw';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
/*---------------------- Providers ---------------------------*/
import { AppSettings } from '../app-settings-service/app-settings.service';
import { Constants } from '../app-settings-service/app-constant.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
@Injectable()
export class RestService {
    networkError: any;
    basicAuthData: string = 'Basic ';
    private bearerAuthData: string = null;
    constructor(private http: HttpClient, private locstr: LocalStorageService, private constants: Constants, private router: Router) {
        this.networkError = {
            "error": {
                "message": this.constants.ERROR_NETWORK_UNAVAILABLE
            }
        }
    }

    /**
     * All get API call will be done using this function
     * @param1: Path of URL
     * @param2: Data to pass to URL if any
     * @param3: skip authorization if needed
     * @param4: timeout - default will be 40 sec if need more or less pass as argument
     * */

    isNetworkAvailable() {
        return navigator.onLine;
    }

    setBearerAuthData = (data) => {
        this.bearerAuthData = data;
    };

    getBearerAuthData = () => {
        if(this.locstr.getObj('authToken')){
            let authToken  = this.locstr.getObj('authToken');
            this.bearerAuthData = "Bearer " + authToken.Auth_Token; 
        }
        return this.bearerAuthData;
    };
    
    /**
     * Function to get super category Id
     */
    getSuperCategoryId = () => {
        return AppSettings.SUPER_CATEGORY_URL;
    };
    

    getCall(path: any, data: any, skipAuth: any, timeout: any): Observable<any> {
        if (this.isNetworkAvailable()) {
            var url = AppSettings.BASE_URL + path;
            timeout = timeout || 1000000;
            data = data || {};
            skipAuth = skipAuth || false;
            let deviceId = this.locstr.get('deviceId', '');
            var headerObj: any = {
                'Content-Type': 'application/json'
            }
            headerObj["deviceType"] = "WEB";
            headerObj["deviceId"] = deviceId;
            headerObj["appVersion"] = "1.0.0";
            headerObj["appVersion"] = "1.0.0";
            var language = this.locstr.get("app-language",'en');
            headerObj["Accept-Language"] = language ? language : "en";
            headerObj['Super-Category'] = this.getSuperCategoryId(); 
            if (skipAuth == false) {
                
                if (this.locstr.get('authToken', null)) {
                 //   headerObj['Auth-Token'] = this.getBearerAuthData();
                   // var authToken = this.locstr.get('authToken', null);
                    headerObj["Authorization"] = this.getBearerAuthData(); 
                } else {
                    this.router.navigate(['/']);
                }
            }
            let headers = new HttpHeaders(headerObj);
            return this.http.get(url, { headers })
                .timeout(timeout);
        } else {
            return Observable.throw(this.networkError);
        }
    }

    getFileCall(path: any, data: any, skipAuth: any, timeout: any): Observable<any> {
        if (this.isNetworkAvailable()) {
            var url = AppSettings.BASE_URL + path;
            timeout = timeout || 1000000;
            data = data || {};
            skipAuth = skipAuth || false;
            let deviceId = this.locstr.get('deviceId', '');
            var headerObj: any = {
                'Accept': 'application/pdf'
            }
            headerObj["deviceType"] = "WEB";
            headerObj["deviceId"] = deviceId;
            headerObj["appVersion"] = "1.0.0";
            var language = this.locstr.get("app-language",'en');
            headerObj["Accept-Language"] = language ? language : "en";
            headerObj['Super-Category'] = this.getSuperCategoryId();
            if (skipAuth == false) {
                if (this.locstr.get('authToken', null)) {
                    headerObj['Auth-Token'] = this.getBearerAuthData();
                    var authToken = this.locstr.get('authToken', null);
                    headerObj["Authorization"] = authToken;
                } else {
                    this.router.navigate(['/']);
                }
            }
            let headers = new HttpHeaders(headerObj);
            return this.http.get(url, { headers, responseType: 'arraybuffer' as 'json' })
                .timeout(timeout);
        } else {
            return Observable.throw(this.networkError);
        }

    }

    /**
     * All POST API call will be done using this function
     * @param1: Path of URL
     * @param2: Data to pass to URL if any
     * @param3: skip authorization if needed
     * @param4: timeout - default will be 40 sec if need more or less pass as argument
     * */
    postCall(path: any, data: any, skipAuth: any, timeout: any, isMultiPart?: boolean): Observable<any> {
        if (this.isNetworkAvailable()) {
            var url = AppSettings.BASE_URL + path;
            timeout = timeout || 40000;
            data = data || {};
            skipAuth = skipAuth || false;
            let deviceId = this.locstr.get('deviceId', '');
            var headerObj: any = {
                deviceType: "WEB",
                deviceName: "desktop",
                appVersion: "1.0.0",
                deviceId: deviceId
            };
            var language = this.locstr.get("app-language",'en');
            headerObj["Accept-Language"] = language ? language : "en";
            headerObj['Super-Category'] = this.getSuperCategoryId();
            console.log("isMultiPart", isMultiPart);
            if (isMultiPart) {
                // headerObj["Content-Type"] = "multipart/form-data"; 
            } else {
                headerObj["Content-Type"] = "application/json";
            }
            // if (skipAuth) {
            //     // if (this.locstr.get('authToken', null)) {
            //     //     // headerObj['Auth-Token'] = this.getBearerAuthData();
            //     //     // var authToken = this.locstr.get('authToken', null);
            //     //     // headerObj["Authorization"] = authToken;
            //     //     headerObj["Authorization"] = this.basicAuthData;
            //     // } else {
            //     //     this.router.navigate(['/']);
            //     // }
            //     headerObj["Authorization"] = this.basicAuthData;
            // }
            // else {
            //     headerObj["Authorization"] = this.basicAuthData;
            // }
            if (skipAuth == false) {
                
                if (this.locstr.get('authToken', null)) {
                 //   headerObj['Auth-Token'] = this.getBearerAuthData();
                   // var authToken = this.locstr.get('authToken', null);
                    headerObj["Authorization"] = this.getBearerAuthData(); 
                } else {
                    this.router.navigate(['/manage-users']);
                }
            }
            let payload = data;//JSON.stringify( data );
            let headers = new HttpHeaders(headerObj);
            return this.http.post(url, payload, { headers })
                .timeout(timeout);
        } else {
            return Observable.throw(this.networkError);
        }
    }

    /**
     * All PUT API call will be done using this function
     * @param1: Path of URL
     * @param2: Data to pass to URL if any
     * @param3: skip authorization if needed
     * @param4: timeout - default will be 40 sec if need more or less pass as argument
     * */
    putCall(path: any, data: any, skipAuth: any, timeout: any, isHeaderValues?: any): Observable<any> {
        if (this.isNetworkAvailable()) {
            var url = AppSettings.BASE_URL + path;
            data = data || {};
            timeout = timeout || 40000;
            skipAuth = skipAuth || false;
            let deviceId = this.locstr.get('deviceId', '');
            var headerObj: any = {
                'Content-Type': 'application/json'
            }
            if (skipAuth == false) {
                if (this.locstr.get('authToken', null)) {
                    var authToken = this.locstr.get('authToken', null);
                    headerObj["Authorization"] = authToken;
                } else {
                    this.router.navigate(['/']);
                }
            }
            headerObj["deviceType"] = "WEB";
            headerObj["appVersion"] = "1.0.0";
            headerObj["deviceId"] = deviceId;
            var language = this.locstr.get("app-language",'en');
            headerObj["Accept-Language"] = language ? language : "en";
            headerObj['Super-Category'] = this.getSuperCategoryId();
            let payload = JSON.stringify(data);
            let headers = new HttpHeaders(headerObj);
            return this.http.put(url, payload, { headers })
                .timeout(timeout);
        } else {
            return Observable.throw(this.networkError);
        }
    }

    deleteCall(path: any, data: any, skipAuth: any, timeout: any): Observable<any> {
        var url = AppSettings.BASE_URL + path;
        timeout = timeout || 40000;
        data = data || {};
        skipAuth = skipAuth || false;
        let deviceId = this.locstr.get('deviceId', '');
        var headerObj: any = {
            'Content-Type': 'application/json'
        }
        headerObj["deviceType"] = "WEB";
        headerObj["appVersion"] = "1.0.0";
        headerObj["deviceId"] = deviceId;
        var language = this.locstr.get("app-language",'en');
        headerObj["Accept-Language"] = language ? language : "en";
        headerObj['Super-Category'] = this.getSuperCategoryId();
        if (skipAuth == false) {
            if (this.locstr.get('authToken', null)) {
                var authToken = this.locstr.get('authToken', null);
                 headerObj["Authorization"] = this.getBearerAuthData();;
                headerObj['Auth-Token'] = this.getBearerAuthData();
            } else {
                // this.router.navigate(['/']);
            }
        }
        let payload = JSON.stringify(data);
        let headers = new HttpHeaders(headerObj);
        return this.http.delete(url, { headers })
            .timeout(timeout);
    }

    /**
 * All POST API call will be done using this function
 * @param1: Path of URL
 * @param2: Data to pass to URL if any
 * @param3: skip authorization if needed
 * @param4: timeout - default will be 40 sec if need more or less pass as argument
 * */
    postLoginCall(path: any, data: any, skipAuth: any, timeout: any): Observable<any> {
        if (this.isNetworkAvailable()) {
            var url = AppSettings.BASE_URL + path;
            timeout = timeout || 40000;
            data = data || {};
            skipAuth = skipAuth || false;
            var headerObj: any = {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
            let deviceId = this.locstr.get('deviceId', '');
            if (!deviceId) {
                deviceId = "" + new Date().getTime();
                this.locstr.set('deviceId', deviceId);
            }
            if (skipAuth) {
                headerObj["Authorization"] = this.basicAuthData;
                //headerObj["Authorization"] = this.getBearerAuthData();
            } else {
                headerObj["Authorization"] = this.basicAuthData;
                //  headerObj["Authorization"] = this.getBearerAuthData();
            }
            var language = this.locstr.get("app-language",'en');
            headerObj["Accept-Language"] = language ? language : "en";
            headerObj["deviceType"] = "WEB";
            headerObj["deviceName"] = "desktop";
            headerObj["appVersion"] = "1.0.0";
            headerObj["deviceId"] = deviceId;
            headerObj['Super-Category'] = this.getSuperCategoryId();
            let payload = data;//JSON.stringify( data );
            let headers = new HttpHeaders(headerObj);
            console.log("headers....", headers);
            return this.http.post(url, payload, { headers })
                .timeout(timeout);
        } else {
            return Observable.throw(this.networkError);
        }
    }

    getGoogleApi(path: string): Observable<any> {
        if (this.isNetworkAvailable()) {
            var url = AppSettings.GOOGLE_URL + path;
            var timeout = 1000000;
            return this.http.get(url)
                .timeout(timeout);
        } else {
            return Observable.throw(this.networkError);
        }
    }

    /**
     * All externalCall API call will be done using this function if any
     * */
    externalCall(url: any, params: any, options: any): Observable<any> {
        return null;
    }
   

}