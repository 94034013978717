/*--------------------Angular related components---------------*/
import { Injectable } from '@angular/core';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Observable } from 'rxjs/Observable';
/*-----------------App Providers---------------------*/
import { AppSettings } from '../app-settings-service/app-settings.service';
import { LocalStorageService } from '../local-storage-service/local-storage.service';
import { RestService } from '../rest-service/rest.service';
import { CommonService } from '../common-service/common-service';

@Injectable()
export class AuthServices {
    errorMsg: string;
    successMsg: string;
    constructor(private commonService: CommonService, private restService: RestService, public locstr: LocalStorageService,
    ) {
    }

    /**
     * Function to perform login
     * @param data
     */
    // BASE API CALL METHODS
    get(path: String, data: any, skipAuth: boolean = false): Observable<any> {
        return this.restService.getCall(path, data, true, null)
            .map(res => res.json())
            .catch(this.commonService.handleError);
    }

    post(path: string, data: any, skipAuth: boolean = false): Observable<any> {
        return this.restService.postCall(path, data, skipAuth, null)
            // .map(res => res.json())
            .catch(this.commonService.handleError);
    }

    put(path: string, data: any, skipAuth: boolean = false): Observable<any> {
        return this.restService.putCall(path, data, true, null)
            .map(res => res.json())
            .catch(this.commonService.handleError);
    }

    delete(path: string, data: any, skipAuth: boolean = false): Observable<any> {
        return this.restService.deleteCall(path, data, true, null)
            .map(res => res.json())
            .catch(this.commonService.handleError);
    }

    // API CALLS

    forgotPassword(forgotData): Observable<any> {
        return this.post(AppSettings.FORGOT_PASSWORD_URL, forgotData, true)
    }

    verifyOTP(otpData: { email: string; otp: string; }) {
        return this.post(AppSettings.VERIFY_OTP_URL, otpData, true)
    }

    resetPassword(resetPasswordData) {
        return this.post(AppSettings.RESET_PASSWORD, resetPasswordData, true)
    }

    login(loginData: { email: string; mobile_number: string; password: string; grant_type: string; country_code: string; is_emp: boolean; }) {
        let loginDataTosend = `email=${loginData.email}&mobile_number=${loginData.mobile_number}&country_code=${loginData.country_code}&password=${loginData.password}&grant_type=${loginData.grant_type}&is_emp=${loginData.is_emp}`;
        return this.restService.postLoginCall(AppSettings.LOGIN_URL, loginDataTosend, false, null)
    }

    public refreshToken(refreshToken: string) {
        let loginDataTosend = `refresh_token=${refreshToken}&grant_type=refresh_token`;
        return this.restService.postLoginCall(AppSettings.REFRESH_TOKEN, loginDataTosend, false, null)
    }

    changePassword(passwordData: any): Observable<any> {
        return this.post(AppSettings.CHANGE_PASSWORD, passwordData, null)
    }

    /**
     * Function to save firebase token
     * @param data
     */
    saveFirebaseToken(data: any): Observable<any> {
        var path = AppSettings.SAVE_FIREBASE_TOKEN;
        return this.restService.postCall(path, data, true, null)
            .map(res => res)
            .catch(this.commonService.handleError);
    }

    /**
     * Function to perform reset password web-service integration
     * @param data
     */
    resendVerficationCode(data: any): Observable<any> {
        var path = AppSettings.RESEND_OTP_URL;
        return this.restService.postCall(path, data, true, null)
            .map(res => res.json())
            .catch(this.commonService.handleError);
    }

    getToken(): string {
        return localStorage.getItem('token');
    }

    /** 
    * Function to get refresh_token, auth_token, etc using previous refresh token 
    * Call to Glosary App backend
    * @param code
    */
    getTokensFromRefreshToken = (refreshToken) => {
        console.log("getTokensFromRefreshToken :: code", refreshToken);
        let path = AppSettings.REFRESH_TOKEN;
        console.log("getTokensFromRefreshToken :: path");
        return this.restService.getCall(path, null, true, null)
            .map(res => {
                console.log("getTokensFromRefreshToken response--", res);
                return res;
            })
            .catch(this.commonService.handleError);
    };

}