import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { Router } from '@angular/router';
/*-------------------- Providers ----------------------------*/
import { UserService } from '../../providers/user-service/user.service';
@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private userService: UserService, private router: Router) {
    }
    canActivate() {
        if (this.userService.getIsUserLoggedIn()) {
            // AuthGuard# Passed
            return true;
        } else {
            // AuthGuard# Failed
            this.router.navigate(['']);
           // window.alert("You don't have permission to view this page");
            return false;
        }
    }
}